import React, {Component} from "react";
import {Card, Grid, Button, CircularProgress} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";

import {
  firebaseSendPasswordResetEmail,
  setLoading,
} from "../../redux/actions/LoginActions";

const styles = (theme) => ({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

class ForgotPassword extends Component {
  state = {
    email: "",
  };

  handleChange = (event) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleFormSubmit = () => {
    const {firebaseSendPasswordResetEmail, setLoading} = this.props;
    setLoading("FORGOT_PASSWORD_LOADING");
    if (firebaseSendPasswordResetEmail) {
      firebaseSendPasswordResetEmail({...this.state});
    }
  };

  render() {
    const {
      classes,
      login: {loading, error},
      history,
    } = this.props;
    const {email} = this.state;

    return (
      <div className="signup flex justify-center w-full h-full-screen">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-8 flex justify-center items-center h-full">
                  <h1>Dynamogramm</h1>
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-9 h-full bg-light-gray position-relative">
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      className="mb-6 w-full"
                      variant="outlined"
                      label="Email"
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "this field is required",
                        "email is not valid",
                      ]}
                    />
                    <div className="flex flex-wrap items-center mb-4">
                      <div className={classes.wrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          type="submit">
                          Reset Password
                        </Button>
                        {loading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                      <span className="ml-4 mr-2">or</span>
                      <Button
                        className="capitalize"
                        onClick={() => history.push("/signin")}>
                        Sign in
                      </Button>
                    </div>
                    {error && error.message && (
                      <div className="py-4 bg-error w-full text-white text-center mt-3 mb-3">
                        {error.message}
                      </div>
                    )}
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  firebaseSendPasswordResetEmail: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  login: state.login,
});

export default withStyles(styles, {withTheme: true})(
  withRouter(
    connect(mapStateToProps, {firebaseSendPasswordResetEmail, setLoading})(
      ForgotPassword
    )
  )
);
