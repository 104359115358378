import React, {Component, useEffect} from "react";
import {Card, CircularProgress, Grid, Button} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";

import {
  firebaseSignUpWithEmailAndPassword,
  setLoading,
} from "../../redux/actions/LoginActions";

const styles = (theme) => ({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

class SignUp extends Component {
  state = {
    displayName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  componentDidMount() {
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    // remove rule when it is not needed
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }

  handleChange = (event) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleFormSubmit = (event) => {
    const {firebaseSignUpWithEmailAndPassword, setLoading} = this.props;
    setLoading("SIGNUP_LOADING");
    if (firebaseSignUpWithEmailAndPassword) {
      firebaseSignUpWithEmailAndPassword({...this.state});
    }
  };

  render() {
    const {
      classes,
      login: {loading, error},
      history,
    } = this.props;
    const {displayName, email, password, confirmPassword} = this.state;
    return (
      <div className="signup flex justify-center w-full h-full-screen">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-8 flex justify-center bg-light-gray items-center h-full">
                  <h1>Dynamogramm</h1>
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-9 h-full">
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      className="mb-6 w-full"
                      variant="outlined"
                      label="Display Name"
                      onChange={this.handleChange}
                      type="text"
                      name="displayName"
                      value={displayName}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                    <TextValidator
                      className="mb-6 w-full"
                      variant="outlined"
                      label="Email"
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "this field is required",
                        "email is not valid",
                      ]}
                    />
                    <TextValidator
                      className="mb-4 w-full"
                      label="Password"
                      variant="outlined"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      value={password}
                      validators={["required", "matchRegexp:^.{6,}$"]}
                      errorMessages={[
                        "this field is required",
                        "Password must be at least 6 characters",
                      ]}
                    />
                    <TextValidator
                      className="mb-4 w-full"
                      label="Confirm password"
                      variant="outlined"
                      onChange={this.handleChange}
                      name="confirmPassword"
                      type="password"
                      value={confirmPassword}
                      validators={["isPasswordMatch", "required"]}
                      errorMessages={[
                        "password mismatch",
                        "this field is required",
                      ]}
                    />
                    <div className="flex items-center">
                      <div className={classes.wrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          type="submit">
                          Sign up
                        </Button>
                        {loading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                      <span className="mx-2 ml-5">or</span>
                      <Button
                        className="capitalize"
                        onClick={() => history.push("/signin")}>
                        Sign in
                      </Button>
                    </div>
                  </ValidatorForm>
                  {error && error.message && (
                    <div className="py-4 bg-error w-full text-white text-center mt-3 mb-3">
                      {error.message}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  firebaseSignUpWithEmailAndPassword: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  login: state.login,
});

export default withStyles(styles, {withTheme: true})(
  withRouter(
    connect(mapStateToProps, {firebaseSignUpWithEmailAndPassword, setLoading})(
      SignUp
    )
  )
);
