import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import { event as currentEvent } from "d3";
import * as d3Collection from "d3-collection";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
// import SettingsIcon from "@material-ui/icons/FormatListBulleted";
// import Paper from "@material-ui/core/Paper";
// import Button from "@material-ui/core/Button";
// import InputBase from "@material-ui/core/InputBase";
// import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
// import SearchIcon from "@material-ui/icons/Search";
import NodesDataService from "../../../services/firebase/NodesDataService";
import LinksDataService from "../../../services/firebase/LinksDataService";
import PeopleDataService from "../../../services/firebase/PeopleDataService";
import {
  getPeopleRoleForNodeV2,
  getPeopleTeamForNode,
} from "../../../helpers/PeopleDataTable";
import autocomplete from "./autocomplete";
import "../d3/index.style.scss";
import "./custom-layout.scss";
import "./autocomplete.style.scss";
import MaterialTable from "material-table";
import { getInfoboxTableOptions, getOptions } from "helpers/DataTable";

let json_data = [];
let data_flat = [];

let svg;
let nodes = [];
let links = [];
let peoples = [];
let data = {
  nodes: [],
  links: [],
  people: [],
};
let prevId;
let prevName;
let clicked = false;
let currentLevel = 0;
let legendClicked = false;
let width = 1500;
let containerWidth = '100%';
let height = 1000;
let stickyTimeVar;
let infoBoxTimeVar;
let stickyCctTimeVar;
let stickyBoxFlag = false;
let stickyBoxCctFlag = false;
let isFirstTooltip = false;
let isFirstInfobox = false;
let currentSelected = null;

export default function D3ChartComponent(props) {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showCCT, setShowCCT] = useState(false);
  const [peopletableColumns, setPeopleTableColumns] = useState([]);
  const [peopleTableData, setPeopleTableData] = useState([]);
  const [teamstableColumns, setTeamsTableColumns] = useState([]);
  const [teamsTableData, setTeamsTableData] = useState([]);

  // const [isFirstTooltip, setIsFirstTooltip] = useState(false);
  const useStyles = makeStyles((theme) => ({
    legend: {
      padding: "0px",
    },
    listItem: {
      float: "left",
      marginRight: "10px",
      fontSize: "16px",
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
      [theme.breakpoints.up("sm")]: {
        marginLeft: "35%",
        marginTop: "0px",
      },
    },
    listText: {
      border: "1px solid #ccc",
      float: "left",
      width: "12px",
      height: "12px",
      background: "black",
      margin: "2px",
    },
    circle: {
      width: "36px",
      height: "36px",
      background: "black",
      border: "1px solid #ccc",
      float: "left",
      borderRadius: "50%",
      marginRight: "10px",
    },
    circle1: {
      width: "36px",
      height: "36px",
      background: "springgreen",
      border: "1px solid #ccc",
      float: "left",
      borderRadius: "50%",
      marginRight: "10px",
    },
    circle2: {
      width: "36px",
      height: "36px",
      background: "MediumSeaGreen",
      border: "1px solid #ccc",
      float: "left",
      borderRadius: "50%",
      marginRight: "10px",
    },
    circle3: {
      width: "36px",
      height: "36px",
      background: "Gainsboro",
      border: "1px solid #ccc",
      float: "left",
      borderRadius: "50%",
      marginRight: "10px",
    },
    oval: {
      width: "36px",
      height: "18px",
      background: "#D0F0C0",
      border: `3px solid black`,
      float: "left",
      borderRadius: "18px / 9px",
      marginRight: "10px",
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
    },
    headerText: {
      marginLeft: "10px",
      fontWeight: "bold",
    },
    legendHeader: {
      marginTop: "24px",
      [theme.breakpoints.up("sm")]: {
        marginLeft: "35%",
        marginTop: "0px",
      },
    },
    root: {
      padding: "2px 4px",
      marginTop: "16px",
      marginBottom: "24px",
      display: "flex",
      alignItems: "center",
      border: `3px solid black`,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    button: {
      backgroundColor: "#228B22",
      "&:hover": {
        backgroundColor: "#228B22",
        color: "#ffffff",
      },
    },
    chartContainer: {
      display: "inline-block",
      margin: "0 auto",
      width: "100%",
    },
  }));

  useEffect(() => {
    let mounted = true;

    // Getting nodes from firebase
    NodesDataService.getAll().then((items) => {
      if (items && items.length) {
        items = _.orderBy(items, ["order"], ["asc"]);
      }

      if (mounted) {
        nodes = items;
        data = {
          ...data,
          nodes: nodes,
        };
      }
    });

    // Getting links from firebase
    LinksDataService.getAll().then((items) => {
      if (mounted) {
        links = items;
        data = {
          ...data,
          links: links,
        };
        // width = +d3.select(".chart").style("width").slice(0, -2);
        // height = window.innerHeight;
        // drawChart(height, width);
      }
    });

    // Getting people from firebase
    PeopleDataService.getAll().then((items) => {
      if (mounted) {
        peoples = items;
        data = {
          ...data,
          people: peoples,
        };
        // width = +d3.select(".chart").style("width").slice(0, -2);
        // height = window.innerHeight;
        drawChart(height, width, containerWidth);
      }
    });

    return () => (mounted = false);
  }, []);

  const handleNodeDragDrop = (node) => {
    // Getting nodes from firebase
    NodesDataService.update(node)
      .then((items) => { })
      .catch((error) => {
        console.log(
          "An error occurred while updating position for the node =>",
          error
        );
      });
  };

  //function for CCT Radio box list
  function toggleCCT(target) {
    let elementsWithCCTClasses = "";

    if (target.id === "cct_all") {
      elementsWithCCTClasses = document.querySelectorAll("*[class*='CCT-']");
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "block";
      });

      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='outer-plt']"
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "block";
      });

      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='zoom-outer-oval']"
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "block";
      });
    } else if (target.id === "cct_plt_sop") {
      elementsWithCCTClasses = document.querySelectorAll("*[class*='CCT-']");
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "none";
      });

      //Show the 2 eclipses related to plt
      elementsWithCCTClasses = document.querySelectorAll("*[class*='CCT-plt']");
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "block";
      });

      //Show the a eclipse related to S&OP
      elementsWithCCTClasses = document.querySelectorAll("*[class*='CCT-sop']");
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "block";
      });

      // show the lines between exec and plt eclipses
      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='outer-plt']"
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "block";
      });

      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='zoom-outer-oval']"
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "none";
      });

      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='zoom-outer-oval-plt']"
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "block";
      });
    } else if (target.id === "cct_none") {
      elementsWithCCTClasses = document.querySelectorAll("*[class*='CCT-']");
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "none";
      });

      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='outer-plt']"
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "none";
      });

      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='zoom-outer-oval']"
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "none";
      });
    } else {
      elementsWithCCTClasses = document.querySelectorAll("*[class*='CCT-']");
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "none";
      });

      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='outer-plt']"
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "none";
      });

      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='zoom-outer-oval']"
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = "none";
      });

      //Show the eclipse related to selected CCT Id
      const selectedCCTElement = document.querySelector("#CCT-" + target.id);
      const selectedCCTElementText = document.querySelector(
        "text#CCT-" + target.id
      );

      selectedCCTElement.style.display = "block";
      selectedCCTElementText.style.display = "block";
    }
  }

  function addEntity(origJSON, data, category) {
    let name_id = data.name.replace(/[^a-zA-Z ]/g, "");
    let type = category === "CCT" ? "oval" : "circle";
    origJSON.nodes.push({
      id: `${category}-${name_id}`,
      name: data.name,
      type: type,
    });
    return origJSON;
  }

  function removeEntity(obj, id) {
    const relevantNodes = Object.entries(obj.nodes) // converts each entry to [key, value]
      .filter(([k, v]) => v.id !== id) // define the criteria to include/exclude items
      .reduce((acc, [k, v]) => {
        acc[k] = v;
        return acc; // this function can be improved, it converts the [[k, v]] back to {k: v, k: v, ...}
      }, {});

    const relevantLinks = Object.entries(obj.links) // converts each entry to [key, value]
      .filter(([k, v]) => v.start_id !== id && v.end_id !== id) // define the criteria to include/exclude items
      .reduce((acc, [k, v]) => {
        acc[k] = v;
        return acc; // this function can be improved, it converts the [[k, v]] back to {k: v, k: v, ...}
      }, {});

    return {
      nodes: Object.values(relevantNodes),
      links: Object.values(relevantLinks),
    };
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const drawChart = (height, width, containerWidth) => {
    const colorRanges = [
      ["#18bc57", "#009A3D", "#067736"],
      ["#95C11C", "#93c219", "#5cb229"],
      ["#77be90", "#3db18f", "#20aa8e"],
      ["#b1c2c4", "#b1b2b4", "#4B4A4B"],
      ["#005371", "#005472", "#003a57"], //exec
    ];

    const color = d3
      .scaleOrdinal()
      .domain([0, 1, 2, 3, 4])
      //.range(["ForestGreen", "springgreen", "MediumSeaGreen", "Gainsboro"]);
      .range(["#009A3D", "#93c219", "#78BE90", "#b1b2b4"]);

    function addEntity(origJSON, data, category) {
      let name_id = data.name.replace(/[^a-zA-Z ]/g, "");
      let type = category === "CCT" ? "oval" : "circle";
      origJSON.nodes.push({
        id: `${category}-${name_id}`,
        name: data.name,
        type: type,
      });
      return origJSON;
    }

    function removeEntity(obj, id) {
      const relevantNodes = Object.entries(obj.nodes) // converts each entry to [key, value]
        .filter(([k, v]) => v.id !== id) // define the criteria to include/exclude items
        .reduce((acc, [k, v]) => {
          acc[k] = v;
          return acc; // this function can be improved, it converts the [[k, v]] back to {k: v, k: v, ...}
        }, {});

      const relevantLinks = Object.entries(obj.links) // converts each entry to [key, value]
        .filter(([k, v]) => v.start_id !== id && v.end_id !== id) // define the criteria to include/exclude items
        .reduce((acc, [k, v]) => {
          acc[k] = v;
          return acc; // this function can be improved, it converts the [[k, v]] back to {k: v, k: v, ...}
        }, {});

      return {
        nodes: Object.values(relevantNodes),
        links: Object.values(relevantLinks),
      };
    }

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    const drag = (simulation, link) => {
      // function dragstarted(event, d) {

      //   if (!event.active) simulation.alphaTarget(0.03).restart();
      //   d.fx = d.x;
      //   d.fy = d.y;
      // }

      // function dragged(event, d) {
      //   d.fx = event.x;
      //   d.fy = event.y;
      // }

      // function dragended(event, d) {
      //   if (!event.active) simulation.alphaTarget(0.03);
      //   d.fx = null;
      //   d.fy = null;
      // }

      function dragstarted(d) {
        d3.select(this).raise().classed("active", true);
      }

      function dragged(event, d) {
        d3.select(this).attr(
          "transform",
          `translate(${(d.x = event.x)}, ${(d.y = event.y)})`
        );
        link
          .attr("x1", (d) => {
            return d.source.x;
          })
          .attr("y1", (d) => d.source.y)
          .attr("x2", (d) => d.target.x)
          .attr("y2", (d) => d.target.y);
      }

      function dragended(d) {
        d3.select(this).classed("active", false);
        handleNodeDragDrop(d && d.subject);
        simulation.stop();
        console.log("drag ended", d);
      }

      return d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    };

    function getTruncated(d) {
      if (d.length > 15) {
        return d.split("&")[0];
      } else {
        return d;
      }
    }

    // function to get and cut off the string including 15+ length
    const getLabel = (d) => {
      if (d.r < 15) {
        return d.data.name.length < 15
          ? d.data.name
          : d.data.name.substring(0, 12) + "...";
      } else if (d.r < 20) {
        return d.data.name.length < 20
          ? d.data.name
          : d.data.name.substring(0, 17) + "...";
      } else if (d.r < 25) {
        return d.data.name.length < 25
          ? d.data.name
          : d.data.name.substring(0, 23) + "...";
      } else if (d.r < 30) {
        return d.data.name.length < 30
          ? d.data.name
          : d.data.name.substring(0, 27) + "...";
      } else {
        return d.data.name;
      }
    };

    //////////////////////////////////////// DRAW CONTAINER ELEMENTS ////////////////////////////////////////
    svg = d3
      .select(".chart")
      .append("svg")
      .attr("class", "chart-svg")
      .style("background", "white")
      .style("overflow", "hidden")
      .attr("width", containerWidth)
      .attr("height", height);

    colorRanges.map((colorRange, index) => {
      var node = svg
        .append("defs")
        .append("radialGradient")
        .attr("id", "radial-gradient" + index);

      var color = d3.scaleLinear().range(colorRange).domain([-1, 0, 1]);

      node.append("stop").attr("offset", "0%").attr("stop-color", color(-1));

      node.append("stop").attr("offset", "50%").attr("stop-color", color(0));

      node.append("stop").attr("offset", "100%").attr("stop-color", color(1));

      // svg.append("circle")
      // .attr("cx", width * 0.5)
      // .attr("cy", height * 0.2 * index)
      // .attr("r", height * 0.2)
      // .style("opacity", 1.0)
      // .style("fill", `url(#radial-gradient${index})`);
    });

    d3.select(".chart").on("click", function () {
      removePeopleTable()
      removeTeamsTable()
      d3.select("#table table").remove();
      stickyBoxFlag = false;
      stickyBoxCctFlag = false;
      currentSelected = null;
      d3.select(".panel").style("visibility", "hidden");
      d3.select(".panel-box-CCT").style("visibility", "hidden");
      d3.select(".panel-search").style("visibility", "hidden");
      d3.select(".child-pointer").style("visibility", "hidden");
      d3.selectAll("*[class*='border-circle_']").style("visibility", "hidden");
    });

    const g = svg
      .append("g")
      .attr("transform", "translate(" + width / 7 + "," + 100 + ")");
    // .call(
    //   d3
    //     .zoom()
    //     .scaleExtent([1, 5])
    //     .on("zoom", function (event) {
    //       svg.attr("transform", event.transform);
    //       drawChart(height, width);
    //     })
    // );

    // svg= d3.select("svg")
    // .call(d3.zoom().on("zoom", function () {
    //       svg.attr("transform", d3.event.transform)
    //  }))
    // .append("g");
    // var zoom = d3.zoom()
    //       .scaleExtent([1, 8])
    //       .on('zoom', function() {
    //           g.selectAll('circle')
    //           .attr('transform', d3.event.transform);
    //           g.selectAll('polygon')
    //           .attr('transform', d3.event.transform);
    //           g.selectAll('line')
    //           .attr('transform', d3.event.transform);
    //           g.selectAll('path')
    //           .attr('transform', d3.event.transform);
    //           g.selectAll('ellipse')
    //           .attr('transform', d3.event.transform);
    // });
    // svg.call(zoom);

    var outer_dim = width / 2;
    var outer_arc = g
      .append("path")
      .attr(
        "d",
        "M 0, " +
        (outer_dim / 2 + 0) +
        " a " +
        outer_dim / 2 +
        "," +
        outer_dim / 2 +
        " 0 1,0 " +
        outer_dim +
        ",0 a " +
        outer_dim / 2 +
        "," +
        outer_dim / 2 +
        " 0 1,0 " +
        outer_dim * -1 +
        ",0"
      )
      .style("fill", "transparent");

    var dim = width / 6;
    var inner_arc = g
      .append("path")
      .attr(
        "d",
        "M" +
        (outer_dim - dim) / 2 +
        ", " +
        (dim / 2 + (outer_dim - dim) / 2) +
        " a " +
        dim / 2 +
        "," +
        dim / 2 +
        " 0 1,0 " +
        dim +
        ",0 a " +
        dim / 2 +
        "," +
        dim / 2 +
        " 0 1,0 " +
        dim * -1 +
        ",0"
      )
      .style("fill", "transparent");

    // evenly spaces nodes along arc
    const circleCoord = function (circle, node, index, num_nodes) {
      var circumference = circle.node().getTotalLength();
      var pointAtLength = function (l) {
        return circle.node().getPointAtLength(l);
      };
      var sectionLength = circumference / num_nodes;
      var position = sectionLength * index + sectionLength;
      return pointAtLength(circumference - position);
    };

    //////////////////////////////////////// DRAW COMMUNITY ARC LABELS ////////////////////////////////////////
    //Turn the pie chart 90 degrees counter clockwise, so it starts at the left
    const pie = d3
      .pie()
      .startAngle((-90 * Math.PI) / 180)
      .endAngle((-90 * Math.PI) / 180 + 2 * Math.PI)
      .value(function (d) {
        return d.value;
      })
      .padAngle(0.01)
      .sort(null);

    const arc = d3
      .arc()
      .outerRadius(outer_dim / 2 + 50)
      .innerRadius(outer_dim / 2);

    var tooltip = d3
      .select(".chart")
      .append("div")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("font-family", "Source Sans Pro, sans-serif")
      .style("width", "200px")
      .text("");
    // const donut = g.append("g")
    //   .attr('transform', `translate(${outer_dim/2}, ${outer_dim/2})`)

    // //Create the donut slices and also the invisible arcs for the text
    // donut.selectAll(".donutArcSlices")
    //     .data(pie(donutData))
    //     .enter().append("path")
    //     .attr("class", "donutArcSlices")
    //     .attr("d", arc)
    //     .style("fill", 'none')
    //     .each(function(d,i) {
    //         //A regular expression that captures all in between the start of a string
    //         //(denoted by ^) and the first capital letter L
    //         var firstArcSection = /(^.+?)L/;

    //         //The [1] gives back the expression between the () (thus not the L as well)
    //         //which is exactly the arc statement
    //         var newArc = firstArcSection.exec( d3.select(this).attr("d") )[1];
    //         //Replace all the comma's so that IE can handle it -_-
    //         //The g after the / is a modifier that "find all matches rather than
    //         //stopping after the first match"
    //         newArc = newArc.replace(/,/g , " ");

    //         //Create a new invisible arc that the text can flow along
    //         svg.append("path")
    //             .attr("class", "hiddenDonutArcs")
    //             .attr("id", "donutArc"+i)
    //             .attr("d", newArc)
    //             .style("fill", "none");
    //     });

    // //Append the label names on the outside
    // donut.selectAll(".donutText")
    //     .data(donutData)
    //    .enter().append("text")
    //     .attr("class", "donutText")
    //     .attr('font-size', '20px')
    //     .attr("dy", -13)
    //    .append("textPath")
    //     .attr("startOffset","50%")
    //     .style("text-anchor","middle")
    //     .attr("xlink:href",function(d,i){return "#donutArc"+i;})
    //     .text(function(d){return d.name;});

    //////////////////// DRAW LEGEND ////////////////////
    const legend = d3
      .select(".legend")
      .append("svg")
      .attr("class", "legend-svg")
      .style("background", "transparent")
      .attr("width", 810)
      .attr("height", 30)
      .style("z-index", 1)
      .append("g")
      .attr("transform", "translate(15, -135)");

    legend
      .append("circle")
      .attr("transform", "translate(0,150)")
      .attr("r", 15)
      .attr("fill", `url(#radial-gradient0)`);

    legend
      .append("text")
      .style("font-family", "Source Sans Pro, sans-serif")
      .attr("font-size", "14px")
      .style("color", "#4B4A4B")
      .attr("transform", "translate(20,155)")
      .text("Center of Competence");

    legend
      .append("circle")
      .attr("transform", "translate(175,150)")
      .attr("r", 15)
      .attr("fill", `url(#radial-gradient1)`);

    legend
      .append("text")
      .style("font-family", "Source Sans Pro, sans-serif")
      .attr("font-size", "14px")
      .style("color", "#4B4A4B")
      .attr("transform", "translate(195,155)")
      .text("Competence Team");

    legend
      .append("circle")
      .attr("transform", "translate(330,150)")
      .attr("r", 15)
      .attr("fill", `url(#radial-gradient2)`);

    legend
      .append("text")
      .style("font-family", "Source Sans Pro, sans-serif")
      .attr("font-size", "14px")
      .style("color", "#4B4A4B")
      .attr("transform", "translate(350,155)")
      .text("Expert Team");

    legend
      .append("circle")
      .attr("transform", "translate(445,150)")
      .attr("r", 15)
      .attr("fill", `url(#radial-gradient3)`);

    legend
      .append("text")
      .style("font-family", "Source Sans Pro, sans-serif")
      .attr("font-size", "14px")
      .style("color", "#4B4A4B")
      .attr("transform", "translate(465,155)")
      .text("Expert / Expert Group");

    legend
      .append("ellipse")
      .attr("transform", "translate(618,150)")
      .attr("rx", 15)
      .attr("ry", 10)
      .attr("fill", "#FFF")
      .attr("stroke", "#009a3c")
      .attr("stroke-width", "2px");

    legend
      .append("text")
      .style("font-family", "Source Sans Pro, sans-serif")
      .attr("font-size", "14px")
      .style("color", "#4B4A4B")
      .attr("transform", "translate(645,155)")
      .text("Cross-Competence Team");

    updateGraph(JSON.parse(JSON.stringify(data)));

    function updateGraph(data) {
      json_data = data;
      console.log("update data", data);

      const innerCircle = data.nodes.filter((d) => d.type === "inner-circle");
      const outerCircle = data.nodes.filter(
        (d) => (d.type === "circle") & (d.id !== "exec")
      );
      let innerCircleList = innerCircle.map((d) => d.name);
      let outerCircleList = outerCircle.map((d) => d.name);
      const innerCircleNum = innerCircle.length;
      const outerCircleNum = outerCircle.length;
      const ovalsNum = data.nodes.filter((d) => d.type === "oval").length;

      const MAX_RADIUS =
        innerCircleNum === 0
          ? outer_dim / 7.2
          : ovalsNum > 15
            ? outer_dim / 11
            : outerCircleNum > 6
              ? outer_dim / 11
              : outer_dim / 9.2;
      const innerNodeRadiusScale = d3
        .scaleSqrt()
        .domain([0, 5])
        .range([(MAX_RADIUS * 0.5) / 5, MAX_RADIUS * 0.5]);
      const outerNodeRadiusScale = d3
        .scaleSqrt()
        .domain([0, 5])
        .range([MAX_RADIUS / 5, MAX_RADIUS]);
      const radiusAccessor = (d) => {
        // return d.id === 'exec' ? innerNodeRadiusScale(d.value) : outerNodeRadiusScale(d.value)
        return d.id === "exec"
          ? 50
          : d.type === "circle"
            ? 110
            : d.type === "inner-circle"
              ? 60
              : outerNodeRadiusScale(d.value);
      };

      function getFixedCoordinates(d, i) {
        if (d.id === "exec") {
          return { x: outer_dim / 2, y: outer_dim / 2 };
        } else if (d.type === "inner-circle") {
          let p1 = circleCoord(outer_arc, d, i - 1, outerCircleNum); // find coordinates of outer ring to slot the inner bubbles between
          let p2 = circleCoord(outer_arc, d, i, outerCircleNum); // find coordinates of outer ring to slot the inner bubbles between
          let midX = p1.x + (p2.x - p1.x) * 0.5;
          let midY = p1.y + (p2.y - p1.y) * 0.5;
          return { x: midX, y: midY };
        } else if ((d.type === "circle") | (d.type === "inner-circle")) {
          let outer_coord = circleCoord(outer_arc, d, i, outerCircleNum); // CHANGE
          return { x: outer_coord.x, y: outer_coord.y };
        } else {
          return { x: null, y: null };
        }
      }

      // check if any CoC bubble share links and place both side by side
      let linkGrps = d3Collection
        .nest()
        .key((d) => d.start_id)
        .entries(data.links);

      let CoC_pairs = [];
      let CT_pairs = [];
      linkGrps.forEach((d, i) => {
        let targets = d.values.map((d) => d.target);
        let CoC_names = [];
        let CT_names = [];
        targets.forEach((t) => {
          if (t) {
            const foundItem = data["nodes"].find((el) => el.id === t);
            if (t.substring(0, 3) === "CoC") {
              if (foundItem) {
                CoC_names.push(foundItem.name);
              }
            } else {
              if (foundItem) {
                CT_names.push(foundItem.name);
              }
            }
          }
        });
        CoC_names = CoC_names.sort((a, b) =>
          d3.ascending(b.toLowerCase(), a.toLowerCase())
        );
        CT_names = CT_names.sort((a, b) =>
          d3.ascending(b.toLowerCase(), a.toLowerCase())
        );
        let CoC_includes = CoC_pairs.some((a) =>
          CoC_names.every((v, i) => v === a[i])
        );
        let CT_includes = CT_pairs.some((a) =>
          CT_names.every((v, i) => v === a[i])
        );
        if (CoC_names.length === 2 && !CoC_includes) {
          CoC_pairs.push(CoC_names);
        }
        if (CT_names.length === 2 && !CT_includes) {
          CT_pairs.push(CT_names);
        }
      });

      CoC_pairs = CoC_pairs.flat().filter(onlyUnique);
      CT_pairs = CT_pairs.flat().filter(onlyUnique);

      innerCircleList = innerCircleList.sort((a, b) =>
        d3.ascending(b.toLowerCase(), a.toLowerCase())
      );
      innerCircleList = innerCircleList.sort(
        (a, b) => CT_pairs.indexOf(b) - CT_pairs.indexOf(a)
      );
      // outerCircleList = outerCircleList.sort((a, b) => d3.ascending(b.toLowerCase(), a.toLowerCase())) //hakuna
      // outerCircleList = outerCircleList.sort((a, b) => CoC_pairs.indexOf(b) - CoC_pairs.indexOf(a))

      data.nodes = data.nodes.sort(
        (a, b) =>
          outerCircleList.indexOf(b.name) - outerCircleList.indexOf(a.name)
      );
      data.nodes = data.nodes.sort(
        (a, b) =>
          innerCircleList.indexOf(b.name) - innerCircleList.indexOf(a.name)
      );

      data.nodes.forEach((d, i) => {
        if (innerCircleList.indexOf(d.name) !== -1) {
          d.index = innerCircleList.indexOf(d.name);
        } else if (outerCircleList.indexOf(d.name) !== -1) {
          d.index = outerCircleList.indexOf(d.name);
        } else {
          d.index = 0;
        }
      });

      let outer_poly = [];
      let inner_poly = [];
      data["nodes"].forEach((d, i) => {
        let PARENT = d.id;
        let index = d.index;
        let coords = getFixedCoordinates(d, index);
        d.groupId = PARENT;
        d.level = 1;
        d.fx = coords.x;
        d.fy = coords.y;
        if (d.type === "inner-circle") {
          inner_poly.push({ x: coords.x, y: coords.y });
        }
        if ((d.type === "circle") & (d.id !== "exec")) {
          outer_poly.push({ x: coords.x, y: coords.y });
        }
        let sum = d3.hierarchy(d).sum((el) => el.value).value;
        d.value =
          d.type === "oval"
            ? 1
            : d.type === "inner-circle"
              ? sum / 1.5
              : sum <= 4
                ? (sum + 1) * 2
                : sum + 1;
        data_flat.push(d);
        d.truncated_name = getTruncated(d.name);
        d.children &&
          d.children.forEach((el) => {
            el.truncated_name = getTruncated(el.name);
            el.groupId = PARENT;
            el.level = 2;
            el.value = el.children ? el.children.length : 5;
            data_flat.push(el);
            el.children &&
              el.children.forEach((el1) => {
                el1.truncated_name = getTruncated(el1.name);
                el1.groupId = PARENT;
                el1.level = 3;
                el1.value = el1.children ? el1.children.length : 1;
                data_flat.push(el1);
                el1.children &&
                  el1.children.forEach((el2) => {
                    el2.truncated_name = getTruncated(el2.name);
                    el2.groupId = PARENT;
                    el2.level = 4;
                    el2.value = 1;
                    el2.value = el2.children ? el2.children.length : 1;
                    data_flat.push(el2);
                  });
              });
          });
      });

      const links = data.links.map((d) => d);
      const nodes = data.nodes.map((d) => d);

      let linksLst = [];
      // Processing links
      if (links && links.length) {
        links.forEach((item) => {
          const sourceNode = nodes && nodes.find((s) => s.id === item.source);
          const targetNode = nodes && nodes.find((s) => s.id === item.target);
          if (sourceNode && sourceNode.id && targetNode && targetNode.id) {
            linksLst.push(item);
          }
        });
      }
      // let nodesLst = nodes;

      // // Looping through the children
      // nodes && nodes.forEach((item)=>{
      //   if (item.children && item.children.length){
      //     item.children.forEach((cItem)=>{
      //       nodesLst.push(cItem);

      //       if (cItem.children && cItem.children.length){
      //         cItem.children.forEach((gcItem)=>{
      //           nodesLst.push(gcItem);
      //         })
      //       }
      //     })
      //   }
      // })
      const simulation = d3
        .forceSimulation(nodes)
        .force(
          "link",
          d3
            .forceLink(linksLst)
            .id((d) => d.id)
            .strength(0)
            .distance(0)
          // .distance(function (d, i) {
          //   if (d.type === "outer") {
          //     return 140;
          //   } else if (d.type === "outer-oval") {
          //     return 240;
          //   } else if ((d.type === "cct-coc") | (d.type === "cct-ct")) {
          //     return 60;
          //   } else {
          //     return 120;
          //   }
          // })
        )
        .force("charge", d3.forceManyBody().strength(0));
      // .force(
      //   "collide",
      //   d3
      //     .forceCollide()
      //     .strength(0.5)
      //     .radius(function (d) {
      //       if (d.type === "oval") {
      //         return radiusAccessor(d) * 1.2;
      //       } else if (d.type === "inner-circle") {
      //         return radiusAccessor(d) * 1.2;
      //       } else {
      //         return radiusAccessor(d) * 1.1;
      //       }
      //     })
      // );
      //.force("center", d3.forceCenter(width / 2, height / 2));

      //////////////////////////////////////// DRAW GRAPH ELEMENTS ////////////////////////////////////////
      // draw lines for all edge types
      // but only make visible edges between 'Center-of-Competence' and 'Competence Team' circles
      const link = g
        .selectAll("line")
        .data(linksLst)
        .join("line")
        .attr("class", (d) => {
          return d.source.id === "exec"
            ? d.type === "outer-oval"
              ? "exec-source outer-plt"
              : "exec-source"
            : "edge";
        })
        .attr("stroke", "#005472")
        .attr("stroke-width", "2px")
        .attr("stroke-opacity", 0);
      //.attr("stroke-opacity", d => (d.type === 'cct-coc' | d.type === 'cct-ct') ? 0 : 1)

      g.selectAll("polygon.outer-poly")
        .data([outer_poly])
        .join("polygon")
        .transition()
        .duration(300)
        .attr("class", "outer-poly")
        .attr("points", function (d) {
          return d
            .map(function (d) {
              return [d.x, d.y].join(",");
            })
            .join(" ");
        })
        .attr("fill", "transparent")
        .attr("stroke", "#005472")
        .attr("stroke-dasharray", 4)
        .attr("stroke-width", 2); // hakuna outer ploy's border width changed from 2 to zero to display none.

      g.selectAll("polygon.inner-poly")
        .data([inner_poly])
        .join("polygon")
        .transition()
        .duration(300)
        .attr("class", "inner-poly")
        .attr("points", function (d) {
          return d
            .map(function (d) {
              return [d.x, d.y].join(",");
            })
            .join(" ");
        })
        .attr("fill", "transparent")
        .attr("stroke", "transparent");
      //.attr("stroke-dasharray",4)
      //.attr("stroke-width",2);

      // draw group element (acts as a wrapper to nest more elements inside) for all node types
      const nodeG = g
        .append("g")
        .attr("class", "nodesWrapper")
        .selectAll("g")
        .data(nodes)
        .join("g")
        .attr("class", (d) => d.type);

      //d3.selectAll("g.oval")
      //.attr('transform', d => `translate(${d.x}, ${d.y})`)
      // .call(d3.drag()
      //        .on("start", dragstarted)
      //        .on("drag", dragged)
      //        .on("end", dragended)
      //  )

      // draw ovals for 'Cross Competence Team' nodes
      nodeG
        .filter((d) => d.type === "oval")
        .call(drag(simulation, link))
        .append("ellipse")
        .attr("id", (d) => d.id) // tag each entity by its unique ID
        .attr("class", (d) => d.groupId) //
        .attr("rx", (d) => radiusAccessor(d) * 1.25)
        .attr("ry", (d) => radiusAccessor(d) * 0.75)
        .attr("fill", "#FFF")
        .attr("stroke", "#009a3c")
        .attr("stroke-width", "2px");

      // draw main outer and inner circles like Home and Group Governance
      nodeG
        .filter((d) => (d.type === "circle") | (d.type === "inner-circle"))
        .sort((a, b) => b.value - a.value)
        .append("circle")
        .attr("class", (d) => "border-circle_" + d.id)
        .attr("r", (d) => radiusAccessor(d) + 5)
        .attr("stroke", (d) => {
          if (d.id === "exec") return "navy";
          if (d.type !== "inner-circle") return color(0);
          else {
            if (d.id === "strategy") return color(2);
            if (d.id === "sustainability") return color(3);
            else return color(1);
          }
        })
        .attr("stroke-width", "2px")
        .attr("fill", "transparent")
        .style("visibility", "hidden");

      nodeG
        .filter((d) => (d.type === "circle") | (d.type === "inner-circle"))
        .sort((a, b) => b.value - a.value)
        .attr("id", (d) => {
          if (d.type === "circle") return d.id;
        })
        .append("circle")
        .attr("id", (d) => d.id)
        .attr("class", (d) => d.groupId)
        .attr("r", (d) => radiusAccessor(d))
        .attr("cursor", "pointer")
        .attr("fill", (d) => {
          if (d.id === "exec") return `url(#radial-gradient4)`;
          if (d.type !== "inner-circle") return `url(#radial-gradient0)`;
          else {
            if (d.id === "strategy") return `url(#radial-gradient2)`;
            if (d.id === "sustainability") return `url(#radial-gradient3)`;
            else return `url(#radial-gradient1)`;
          }
        })
        .on("click", function (event, d) {
          event.stopPropagation();
          currentLevel = d.data ? d.data.level : d.level; //hakuna
          console.log(currentLevel, d.data ? d.data.level : d.level);
          if (currentLevel === 1) {
            d3.select(".border-circle_" + d.id).style("visibility", "visible");
          }
          currentSelected = d.id;
          return zoom(d, d3.select(this));
        })
        .on("mouseover", async function (event, d) {
          // d3.select(this).attr("stroke", "#000");
          // d3.select(".border-circle_"+d.id).style("visibility","visible")
          removeTeamsTable();
          removePeopleTable();
          tooltip
            .style("visibility", "visible")
            .text(d.data ? d.data.name : d.name);
          if (!isFirstInfobox) {
            d3.select(".panel-box-CCT").style("visibility", "hidden");
            clickCCT(event, d);
            isFirstInfobox = true;
          } else {
            // tooltip.text('');
            infoBoxTimeVar = await setTimeout(
              function () {
                //Start the timer
                d3.select(".panel-box-CCT").style("visibility", "hidden");
                clickCCT(event, d);
              }.bind(this),
              200
            );
          }
        })
        .on("mouseenter", function (d) {
          stickyTimeVar = setTimeout(function () {
            stickyBoxFlag = true;
          }, 300 /* miliseconds */);
        })
        .on("mouseout", async function (d) {
          tooltip.style("visibility", "hidden");
          tooltip.text("");
          clearTimeout(infoBoxTimeVar);
          // d3.select(this).attr("stroke", null);
          // d3.select(".border-circle_"+d.id).style("visibility","hidden")
          if (!stickyBoxFlag) {
            setTimeout(function () {
              d3.select(".panel").style("visibility", "hidden");
              d3.selectAll(".child-pointer").style("visibility", "hidden");
            }, 300 /* miliseconds */);
          }
          clearTimeout(stickyTimeVar);
          //d3.select('.panel').style('visibility', 'hidden')
        })
        .on("mousemove", function (event, d) {
          return tooltip
            .style("top", event.offsetY - 50 + "px")
            .style("left", event.offsetX + "px");
        });

      // for 'Center of Competence' nodes only, execute circle packing
      nodeG
        .append("g")
        .filter((d) => (d.type === "circle") | (d.type === "inner-circle"))
        .each(function (d) {
          drawHexagons(d3.select(this), d, {
            width: radiusAccessor(d) * 2,
            height: radiusAccessor(d) * 2,
          });
        });

      // for 'exec', 'Cross Competence Team' and 'Competence Team' nodes, append label
      // Note: this does not append labels for nested circles
      nodeG
        .append("text")
        .filter(
          (d) =>
            (d.type === "inner-circle" && !d.children) |
            (d.type === "oval") |
            (d.type === "circle" && !d.children)
        )
        .attr("class", (d) => "outer-labels " + d.id)
        .attr("id", (d) => d.id)
        //.style("fill", d => d.id === 'exec' ? "white" : "black")
        .style("fill", (d) =>
          d.id === "exec" ? "white" : d.type === "oval" ? "#14142B" : "#000"
        )
        .attr("font-family", "Source Sans Pro")
        .attr("font-size", (d) => (d.type === "oval" ? "0.8em" : "1.1em"))
        .attr("font-weight", (d) =>
          d.type === "circle" && !d.children ? "bold" : "normal"
        )
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .attr("pointer-events", "none") // ensure text labels are not blocking circles from being hovered upon
        //.attr("dy", d => -radiusAccessor(d)-8) // labels are always placed centralized within circle use 'dy' attribute to adjust it
        .attr("y", 0)
        .attr("dy", (d) => {
          if (d.type !== "oval") {
            if (d.id === "exec") {
              return 0;
            } else {
              if (d.name.length < 11) {
                return 0.5;
              } else if (d.name.length < 13) {
                return 0;
              } else {
                return -0.6;
              }
            }
          } else {
            if (d.name.length > 30) {
              return -0.6;
            } else if (d.name.length > 22) {
              return -0.5;
            } else if (d.name.length > 18) {
              return -0.7;
            } else if (d.name.length > 8) {
              return 0;
            } else {
              return 0.5;
            }
          }
        })
        // .attr('opacity', 1)
        .style("visibility", "visible")
        .text((d) => (d.name.length > 30 ? d.truncated_name + "..." : d.name))
        .call(wrap, 80);

      // display centered horizontal header line for outer main circle
      nodeG
        .append("text")
        .filter(
          (d) =>
            (d.type === "circle" && d.children) ||
            (d.type === "inner-circle" && d.children)
        )
        .attr("class", "header-labels")
        .attr("id", (d) => d.id)
        .style("fill", (d) => {
          if (d.id.split("-")[0] === "CoC") {
            return "white";
          } else {
            return "black";
          }
        })
        .attr("font-family", "Source Sans Pro")
        .attr("font-size", "1.1em")
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .attr("pointer-events", "none") // ensure text labels are not blocking circles from being hovered upon
        //.attr("dy", d => -radiusAccessor(d)-8) // labels are always placed centralized within circle use 'dy' attribute to adjust it
        .attr("y", 0)
        .attr("dy", (d) => {
          if (d.name.length > 30) {
            return 0;
          } else if (d.name.length > 15) {
            return 0;
          } else {
            return 0;
          }
        })
        // .attr('opacity', 0)
        .style("visibility", "visible")
        .text((d) => (d.name.length > 30 ? d.truncated_name + "..." : d.name))
        .call(wrap, 120);

      // however, on zoom, we want to show the full text label of 'Competence Team' nodes instead of abbreviations
      nodeG
        .append("text")
        .filter((d) => !d.children || d.children.length == 0)
        .attr("class", (d) => {
          return d.type === "oval"
            ? d.id === "CCT-plt_culinary" ||
              d.id === "CCT-plt_cleaning" ||
              d.id === "CCT-sop"
              ? "zoom-outer-labels zoom-outer-oval " +
              d.id +
              " zoom-outer-oval-plt"
              : "zoom-outer-labels zoom-outer-oval " + d.id
            : "zoom-outer-labels";
        })
        .style("fill", (d) => {
          return d.id === "exec" ? "white" : "black";
        })
        .attr("font-family", "Source Sans Pro")
        .attr("font-size", "0.3em")
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .attr("pointer-events", "none")
        // .attr("opacity", 0) // do not make the label visible initially
        .style("visibility", "hidden")
        .text((d) => (d.name.length > 30 ? d.truncated_name + "..." : d.name));

      // .call(wrap, 120)

      //////////////////////////////////////// UPDATE ELEMENT POSITIONS ////////////////////////////////

      simulation.on("tick", () => {
        link
          //.attr("x1", d => d.source.id === "exec" ? width/2 : d.source.x) // fix the 'exec' team node in center of container
          //.attr("y1", d => d.source.id === "exec" ? height/2: d.source.y)
          .attr("x1", (d) => {
            return d.source.x;
          })
          .attr("y1", (d) => d.source.y)
          .attr("x2", (d) => d.target.x)
          .attr("y2", (d) => d.target.y);

        //nodeG.attr("transform", d => `translate(${d.id === "exec" ? width/2 : d.x}, ${d.id === "exec" ? height/2 : d.y})`)
        nodeG
          //.filter(d => d.type !== 'oval')
          .attr("transform", (d) => {
            return `translate(${d.x}, ${d.y})`;
          });
      });

      //////////////////////////////////////// INTERACTIVITY ////////////////////////////////////////
      // all mouseover events
      svg
        .selectAll(".oval")
        .attr("cursor", "pointer")
        .on("mouseover", (event, d) => {
          mouseover(event, d);
        })
        .on("mouseenter", (d) => mouseenter(d))
        .on("mouseout", (d) => mouseleave(d))
        .on("mousemove", function (event, d) {
          return tooltip
            .style("top", event.offsetY - 50 + "px")
            .style("left", event.offsetX + "px");
        });

      svg
        .selectAll("circle")
        // .filter(d => d.data && d.data.level < 4)
        .filter((d) => d.data && d.data.level < 5)
        .attr("cursor", "pointer")
        .on("click", function (event, d) {
          event.stopPropagation();
          currentLevel = d.data ? d.data.level : d.level; //hakuna

          currentSelected = d.id;

          d3.select(".border-circle_" + d.data.id).style(
            "visibility",
            "visible"
          );
          return zoom(d, d3.select(this));
        })
        .on("mouseover", async function (event, d) {
          // d3.select(this).attr("stroke", "#000");
          // d3.select(".border-circle_"+d.id).style("visibility","visible")
          tooltip.text("");
          tooltip
            .style("visibility", "visible")
            .text(d.data ? d.data.name : d.name);
          if (!isFirstInfobox) {
            d3.select(".panel-box-CCT").style("visibility", "hidden");
            clickCCT(event, d);
            isFirstInfobox = true;
          } else {
            infoBoxTimeVar = await setTimeout(
              function () {
                //Start the timer
                d3.select(".panel-box-CCT").style("visibility", "hidden");
                clickCCT(event, d);
              }.bind(this),
              200
            );
          }

          // if(!sticky_box_flag){
          //   setTimeout(function () {
          //   d3.select('.panel').style('visibility', 'hidden')
          // }, 3000 /* miliseconds */);
          // }
        })
        .on("mouseenter", function (d) {
          stickyTimeVar = setTimeout(function () {
            stickyBoxFlag = true;
          }, 300 /* miliseconds */);
        })
        .on("mouseout", async function (d) {
          clearTimeout(infoBoxTimeVar);
          tooltip.text("");
          // d3.select(this).attr("stroke", null);
          // d3.select(".border-circle_"+d.id).style("visibility","hidden")
          if (!stickyBoxFlag) {
            setTimeout(function () {
              d3.select(".panel").style("visibility", "hidden");
              d3.selectAll(".child-pointer").style("visibility", "hidden");
            }, 300 /* miliseconds */);
          }
          clearTimeout(stickyTimeVar);
          //d3.select('.panel').style('visibility', 'hidden')
        })
        .on("mousemove", function (event, d) {
          return tooltip
            .style("top", event.offsetY - 50 + "px")
            .style("left", event.offsetX + "px");
        });

      svg
        .selectAll("circle")
        .filter((d) => d.id === "exec")
        .on("click", function (event, d) {
          currentSelected = d.id;
          // event.stopPropagation();
          // d3.selectAll("*[class*='border-circle_']").style("visibility", "hidden");
          // d3.select(".border-circle_exec" ).style("visibility", "visible")
        })
        .on("mouseover", (d) => mouseover1(d))
        .on("mouseout", (event, d) => {
          console.log("exec mouse leave", d);
          mouseleave(d);
        });

      // search box
      const input = document.querySelector(".search-form");

      input.addEventListener("keyup", function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
          let entity = data_flat.find((d) => d.name === input.value); //input: entity name
          let person = findPersonByName(input.value);

          console.log('entity', entity)
          console.log('person', person)

          if (person && person.roles) {
            entity = person.roles.find(
              (role) => role.role && (role.role.toLowerCase() === "lead" || role.role.toLowerCase() === "speaker" || role.role.toLowerCase() === "executive board")
            );

            if(!entity){
              entity = person.roles[0]
            }

            let selection;

            if(entity.node_id.substring(0, 3) !== "CCT"){
              selection = svg.selectAll("circle").filter("#" + entity.node_id);
            } else {
              selection = svg.selectAll("ellipse").filter("#" + entity.node_id);
              setShowCCT(true);
              toggleCCT({ id: "cct_all" });
            };
            let selection_data = selection.data()[0];
            searchInfoBox(selection_data, input.value);
            return zoom(selection_data, selection, true);
          } else if (entity) {
            let selection;

            if( entity.id.substring(0, 3) !== "CCT" ) {
              selection =  svg.selectAll("circle").filter("#" + entity.id);
            } else {
              selection = svg.selectAll("ellipse").filter("#" + entity.id);
              setShowCCT(true);
              toggleCCT({ id: "cct_all" });
            }
            // let selection = svg.selectAll("circle").filter("#" + entity.id);
            let selection_data = selection.data()[0];
            d3.select(".panel-search").style("visibility", "hidden");
            //searchInfoBox(selection_data, input.value);
            createCctInfobox(selection_data);
            return zoom(selection_data, selection, true);
          }
        }
      });

      // button to add/remove entities
      // let newData = dataOrig
      // let clickAdd = 0
      // let newBubbles = ['New Bubble', 'New Bubble 2']
      // document.querySelector(".add-entity").addEventListener('click',function (){
      //   //let dataOrig1 = JSON.parse(JSON.stringify(dataOrig))
      //   newData = addEntity(newData, {name: newBubbles[clickAdd]}, "CoC")
      //   d3.select('.nodesWrapper').remove()
      //   updateGraph(JSON.parse(JSON.stringify(newData)))
      //   clickAdd += 1
      // })

      // let clickRemove = 0
      // let deleteBubbles = ["CoC-home", "corporate_purchasing"]
      // document.querySelector(".remove-entity").addEventListener('click',function (){
      //   //let dataOrig1 = JSON.parse(JSON.stringify(dataOrig))
      //   newData = removeEntity(newData, deleteBubbles[clickRemove])
      //   d3.select('.nodesWrapper').remove()
      //   updateGraph(JSON.parse(JSON.stringify(newData)))
      //   clickRemove += 1
      // })

      function zoom(focus, nodeElement, searchMode = false) {
        // search box
        const input = document.querySelector(".search-form");
        let SearchMode = searchMode; //input.value !== '' ? true : false;

        // input.addEventListener("keyup", function (event) {
        //   if (event.keyCode === 13) {
        //     event.preventDefault()
        //     let entity = data_flat.find(d => d.name === input.value) //input: entity name

        //     if (entity) {
        //       let selection = svg.selectAll('circle').filter("#" + entity.id)
        //       let selection_data = selection.data()[0]
        //       return zoom(selection_data, selection)
        //     }
        //   }
        // })

        svg.on("click", function (event, d) {
          return zoom(d, d3.select(this));
        });

        d3.selectAll(".breadcrumb").selectAll("li").remove();

        let levels = ["Overview"];

        svg
          .selectAll("text")
          // .attr('opacity', 0)
          .style("visibility", "hidden");

        svg
          .selectAll("circle")
          // .attr('opacity', 0)
          .style("visibility", "hidden");
        if (focus) {
          if (SearchMode) {
            currentLevel = focus.data ? focus.depth : 0;
          } else {
          }

          SearchMode = false;
          svg
            .selectAll("circle")
            .filter((d) => {
              return (
                (d.data && d.data.level <= currentLevel + 1) ||
                d.id == "exec" ||
                d.level
              );
            })
            // .attr("opacity", 1);
            .style("visibility", "visible");
          svg.selectAll(".small-icons").attr("opacity", 0);
          svg
            .selectAll("*[class*='border-circle_']")
            .style("visibility", "hidden");
          console.log("focus data", focus);
          if (!focus.data)
            d3.select(".border-circle_" + focus.id).style(
              "visibility",
              "visible"
            );
          else
            d3.select(".border-circle_" + focus.data.id).style(
              "visibility",
              "visible"
            );

          if (focus.parent) {
            if (focus.parent.parent) {
              if (focus.parent.parent.parent) {
                levels.push(focus.parent.parent.parent.data.id);
              }
              levels.push(focus.parent.parent.data.id);
            }
            levels.push(focus.parent.data.id);
          }

          levels.push(focus.data ? focus.data.id : focus.id);

          if (focus.children && focus.children.length) {
            //levels.push(focus.children[0].data ? focus.children[0].data.id : focus.children[0].id)

            if (
              focus.children[0].data &&
              focus.children[0].data.children &&
              focus.children[0].data.children[0].data
            ) {
              //levels.push(focus.children[0].data.children[0].data.id)
              // if(focus.children[0].data.children[0].data.children){
              //   levels.push(focus.children[0].data.children[0].data.children[0].data.id)
              // }
            } else {
              if (focus.children[0].children) {
                // if(focus.children[0].children[0].data){
                //   levels.push(focus.children[0].children[0].data.id)
                // } else {
                //   levels.push(focus.children[0].children[0].id)
                // }
                //if(focus.children[0].children[0].children){
                //levels.push(focus.children[0].children[0].children[0].id)
                //}
              }
            }
          }

          let crumbs = d3
            .selectAll(".breadcrumb")
            .selectAll("li")
            .data(levels.filter((d) => d))
            .enter()
            .append("li")
            .style("cursor", "pointer");

          crumbs
            .append("span")
            .attr("class", (d) => d)
            .html((d) => d.split("-")[0])
            .on("click", function (event, d) {
              let selection = svg.selectAll("circle").filter("#" + d);
              let selection_data = selection.data()[0];
              event.stopPropagation();
              return zoom(selection_data, selection);
            })
            .style("color", (d, i) =>
              i === levels.length - 1 ? "black" : "lightgrey"
            );

          let k = focus.data
            ? width / (focus.r * 5)
            : width / (radiusAccessor(focus) * 5);
          let group = focus.data
            ? data["nodes"].find((d) => d.id === focus.data.groupId)
            : { x: 0, y: 0 };
          let radius = focus.data ? radiusAccessor(focus) : 0;

          if (focus.id === "exec") {
            focus.x = outer_dim / 2;
            focus.y = outer_dim / 2;
          }

          const arr = nodeElement.attr("transform")
            ? getTranslation(nodeElement.attr("transform"))
            : [];
          const x = nodeElement.attr("transform") ? arr[0] : focus.x - radius;
          const y = nodeElement.attr("transform") ? arr[1] : focus.y - radius;

          // create an illusion that clicked node is being zoomed into by shifting container position and zooming into the container (Note: the circle itself is not being transformed/scaled)
          g.transition()
            .duration(750)
            .attr("transform", function () {
              return `translate(${-(
                (group.x + x) * k -
                width / 2
              )},${-((group.y + y) * k - height / 2)})scale(${k})`;
            });

          // svg.selectAll("text")
          //   .transition().duration(250).delay(150)
          //   .attr('opacity', 1)

          svg
            .selectAll("text")
            .filter((d) => {
              // if(d.type === undefined )
              return d;
            })
            .transition()
            .duration(250)
            .delay(150)
            // .attr('opacity', 1)
            .style("visibility", "hidden");

          // top round label out of circle
          svg
            .selectAll("text.arc-labels")
            .selectAll("textPath")
            .attr("font-size", "0.4em")
            .text((d) => d.data.name)
            .style("visibility", (d) => {
              return d.data.level < currentLevel + 1 ? "visible" : "hidden";
            });

          // hide visibility of 'Center of Competence' node labels
          svg
            .selectAll("text.horizontal-labels")
            .transition()
            .duration(250)
            .delay(150)
            // .attr('opacity', 0)
            // .style('visibility', 'visible')
            .style("visibility", (d) => {
              return currentLevel == d.data.level ? "hidden" : "hidden";
            });

          svg
            .selectAll("text.zoom-3-horizontal-labels")
            .transition()
            .duration(250)
            .delay(150)
            // .style("visibility", "hidden")
            .style("visibility", (d) => {
              return currentLevel === 2 ||
                (currentLevel === 3 && !d.data.children)
                ? "visible"
                : "hidden";
            });

          svg
            .selectAll("text.zoom-4-horizontal-labels")
            .transition()
            .duration(250)
            .delay(150)
            .style("visibility", (d) => {
              return currentLevel > 2 ? "visible" : "hidden";
            });

          svg
            .selectAll("text.small-horizontal-labels")
            .transition()
            .duration(250)
            .delay(150)
            .attr("opacity", 1)
            .style("visibility", (d) => {
              return currentLevel === 1 ||
                (currentLevel === 2 && !d.data.children)
                ? "visible"
                : "hidden";
            })
            .attr("font-size", (d) => {
              if (currentLevel === 1) {
                return "0.4em";
              } else if (d.data.level < 3) {
                return "0.4em";
              } else {
                return "0.5em";
              }
            });

          svg
            .selectAll(".zoom-2-horizontal-labels")
            .filter((d) => {
              // if(d.type === 'circle'){
              return d;
              // }
            })
            .transition()
            .duration(250)
            .delay(150)
            // .attr('opacity', 1)
            .style("visibility", "hidden");

          svg
            .selectAll("text.header-labels")
            .transition()
            .duration(250)
            .delay(150)
            // .attr('opacity', 0)
            //for search zoom action of main outer circle, added level condition here
            .style("visibility", (d) =>
              currentLevel === 0 ? "visible" : "hidden"
            );

          svg
            .selectAll("text.outer-labels")
            .transition()
            .duration(250)
            .delay(150)
            // .attr('opacity', 0)
            .style("visibility", "hidden");

          // show full text label of 'Competence Team' nodes
          svg
            .selectAll("text.zoom-outer-labels")
            .transition()
            .duration(250)
            .delay(250)
            //  .attr('opacity', d => (d.id !== 'corporate_governance' | d.id !== 'communications') ? 1 : 0)
            .style("visibility", (d) =>
              (d.id !== "corporate_governance") | (d.id !== "communications")
                ? "visible"
                : "hidden"
            );

          // d3.selectAll(".small-icons").attr('opacity', 0)
          // d3.selectAll("#small-icons-" + focus.data.id)
          //   .attr('opacity', 1)
          //   .on('click', function(d){
          //     d3.event.stopPropagation()
          //     clickCCT(d)
          //   })
        } else {
          console.log("click out space");
          input.value = "";
          SearchMode = false;
          currentLevel = 0;
          stickyBoxFlag = false;
          stickyBoxCctFlag = false;
          currentSelected = null;

          const CCTInfoBoxWrapper = document.querySelector(".panel-box-CCT");
          CCTInfoBoxWrapper.style.visibility = "hidden";
          // d3.select('.panel-box-CCT').style('visibility', 'hidden');
          d3.select(".panel").style("visibility", "hidden");
          d3.select(".child-pointer").style("visibility", "hidden");

          svg
            .selectAll("circle")
            .filter((d) => {
              return (d.data && d.data.level <= 1) || d.id == "exec" || d.level;
            })
            // .attr("opacity", 1)
            .style("visibility", "visible");
          d3.selectAll(`.breadcrumb`).selectAll("li").remove();
          d3.selectAll("*[class*='border-circle_']").style(
            "visibility",
            "hidden"
          );
          // reset to original position
          g.transition()
            .duration(750)
            .attr("transform", function () {
              return `translate(${width / 7},100)scale(1)`;
            });

          // reset to original label visibility
          svg
            .selectAll("text.outer-labels")
            // .attr("opacity", 1)
            .style("visibility", "visible");
          svg
            .selectAll("text.arc-labels")
            .filter((d) => {
              return d.data.level === 1;
            })
            .attr("opacity", 1)
            .style("visibility", "visible");

          // hide all sub level's horizontal labels
          svg
            .selectAll("text.horizontal-labels")
            // .attr("opacity", 1)
            .style("visibility", "hidden");

          // display main outer and inner circle's label
          svg.selectAll("text.header-labels").style("visibility", "visible");

          svg
            .selectAll(".donutText")
            // .attr('opacity', 1)
            .style("visibility", "visible");
          svg
            .selectAll("text.arc-labels textPath")
            .attr("font-size", "1em")
            .text((d) => d.data.truncated_name)
            .style("visibility", (d) => {
              return d.data.level > 1 ? "hidden" : "visible";
            });

          svg.selectAll("text.arc-labels textPath").each(function (d, i) {
            d3.select(this).call(crop, d.r);
          });

          d3.selectAll(".small-icons").attr("opacity", 0);

          svg
            .selectAll("text.small-horizontal-labels")
            .transition()
            .duration(250)
            .delay(150)
            .attr("font-size", (d) => {
              if (currentLevel === 0) {
                return "0.6em";
              }
            });
        }
      }
      // hide all CCT by default
      toggleCCT({ id: "cct_none" });
    }

    function createCctInfobox(d) {
      let panelWrapperCCT = d3.select(".panel-box-CCT");
        //d3.select('.btn-legend').style('opacity', clicked ? 1 : 0)

        let speakersLst = findPersonByRole(d.id, "speaker");

        let speaker_cct = speakersLst && speakersLst.length ? "" : "N.N.";
        speakersLst &&
          speakersLst.map((person, i) => {
            // console.log(person.name)
            if (i + 1 < speakersLst.length) speaker_cct += person.name + ", ";
            else speaker_cct += person.name;
          });

        let executiveList = findPersonByRole(d.id, "executive board");
        
        let executives_cct = executiveList && executiveList.length ? "" : "N.N.";
        executiveList &&
          executiveList.map((person, i) => {
            if (i + 1 < executiveList.length) executives_cct += person.name + ", ";
            else executives_cct += person.name;
          });

        if (
          (d.id && d.id.substring(0, 3) == "CoC") ||
          (d.id && d.id.substring(0, 3) == "CCT")
        ) {
          if (
            speakersLst &&
            speakersLst.length &&
            speakersLst.length > 1 &&
            speaker_cct !== "N.N."
          ) {
            panelWrapperCCT.select(".panel-header2-title").text("Speakers: ");
          } else {
            panelWrapperCCT.select(".panel-header2-title").text("Speaker: ");
          }
        } else {
          panelWrapperCCT.select(".panel-header2-title").text("");
        }

        panelWrapperCCT.select(".panel-header span").text(d.name);
        panelWrapperCCT.select(".panel-header2 span").text(speaker_cct);
        panelWrapperCCT
          .select(".panel-bio-value")
          .text(d.description ? d.description : "");
        panelWrapperCCT.select(".parent-node").text(`Executive Board: ${executives_cct}`);
        panelWrapperCCT
          .select(".self-node")
          .text(d.name + " (" + speaker_cct + ")");
        const members = getPeopleRoleForNodeV2(json_data.people, d.id);

        // Construct the people table
        createTable(
          members,
          ["people_name", "role", "location"],
          ["Name", "Role", "Country"],
          "people-table-div"
        );

        d3.select(".panel-box-CCT").style("visibility", "visible");

    }

    function mouseover1(d) {
      svg.selectAll("line.exec-source").attr("stroke-opacity", 1);
    }

    function mouseenter() {
      stickyCctTimeVar = setTimeout(function () {
        stickyBoxCctFlag = true;
      }, 300 /* miliseconds */);
    }

    async function mouseover(event, ele) {
      tooltip.style("visibility", "visible").text(ele.name);

      console.log('aca no es')

      if (!isFirstInfobox) {
        d3.select(".panel").style("visibility", "hidden");
        clickCCT(event, ele);
        isFirstInfobox = true;
      } else {
        infoBoxTimeVar = await setTimeout(
          function () {
            //Start the timer
            d3.select(".panel").style("visibility", "hidden");
            clickCCT(event, ele);
          }.bind(this),
          200
        );
      }

      // find circles connected to oval
      let nodes = [];
      let conn = ele.connections && ele.connections.map((d) => d);

      nodes.push(conn);
      nodes.push(ele.id);
      nodes = nodes.flat();

      // diminish the opacity of ovals not hovered upon
      svg
        .selectAll("ellipse")
        .filter((d) => d.id !== ele.id)
        .attr("opacity", 0.1);

      svg
        .selectAll("text.zoom-outer-oval")
        .filter((d) => d.id !== ele.id)
        // .attr("opacity", 0.1);
        .style("visibility", "hidden");

      svg
        .selectAll("text.outer-labels")
        .filter((d) => d.id !== ele.id)
        .attr("opacity", 0.1);
      // .style('visibility', 'hidden')

      // diminish the opacity of non-connected circles (including nested circles)
      svg
        .selectAll("circle")
        .filter((d) => {
          return (
            (d.data && d.data.level <= currentLevel + 1) ||
            d.id == "exec" ||
            d.level
          );
        })
        .attr("opacity", 0.1);
      // .style('visibility', 'hidden')

      svg.selectAll(".small-icons").attr("opacity", 0);
      svg
        .selectAll("text.arc-labels")
        .attr("opacity", 0)
        .style("visibility", "hidden");
      svg
        .selectAll("text.horizontal-labels")
        // .attr("opacity", 0)
        .style("visibility", "hidden");
      svg
        .selectAll("text.header-labels")
        // .attr("opacity", 0)
        .style("visibility", "hidden");
      svg
        .selectAll("text.small-horizontal-labels")
        // .attr("opacity", 0)
        .style("visibility", "hidden");

      nodes.forEach((el) => {
        svg
          .selectAll("circle#" + el)
          .attr("opacity", 1)
          .style("visibility", "visible");
        svg
          .selectAll("ellipse#" + el)
          .attr("opacity", 1)
          .style("visibility", "visible");
        svg
          .selectAll("text.outer-labels#" + el)
          .attr("opacity", 1)
          .style("visibility", (d) => {
            return currentLevel > 0 ? "hidden" : "visible";
          });
        svg
          .selectAll("text.header-labels#" + el)
          .attr("opacity", 1)
          .style("visibility", "visible");
        svg
          .selectAll("text.horizontal-labels#" + el)
          .attr("opacity", 1)
          .style("visibility", "visible");
        svg
          .selectAll("text.small-horizontal-labels#" + el)
          .attr("opacity", 1)
          .style("visibility", "visible");
      });

      let CoC_conn = [];
      let CT_conn = [];
      conn &&
        conn.forEach((d) => {
          if (d.split("-")[0] === "CoC") {
            CoC_conn.push(d.split("-")[1]);
          } else if (d.split("-")[0] === "CT") {
            CT_conn.push(d.split("-")[1]);
          }
        });
      let contains = containsAny(CoC_conn, CT_conn);

      if (contains.bool) {
        contains.item.forEach((el) => {
          svg
            .selectAll("text.arc-labels#" + `CoC-${el}`)
            // .attr("opacity",1)
            .style("visibility", "visible");
          svg
            .selectAll("text.header-labels#" + `CoC-${el}`)
            // .attr("opacity", 0)
            .style("visibility", "hidden");
        });
      }

      // diminish opacity of all connector lines
      svg.selectAll("line").attr("stroke-opacity", 0);
      //.attr("stroke-opacity", d => (d.type === 'cct-coc' | d.type === 'cct-ct') ? 0 : 0.1)

      svg.selectAll("polygon").attr("opacity", 0.1);

      function containsAny(source, target) {
        var result = source.filter(function (item) {
          return target.indexOf(item) > -1;
        });
        return { bool: result.length > 0, item: result };
      }
    }

    function mouseleave(obj = null) {
      tooltip.text("");
      tooltip.style("visibility", "hidden");
      d3.selectAll(".child-pointer").style("visibility", "hidden");
      if (!stickyBoxCctFlag) {
        setTimeout(function () {
          d3.select(".panel-box-CCT").style("visibility", "hidden");
        }, 3000 /* miliseconds */);
      }
      clearTimeout(infoBoxTimeVar);
      clearTimeout(stickyCctTimeVar);

      svg
        .selectAll("ellipse")
        .attr("opacity", 1)
        .style("visibility", "visible");

      svg
        .selectAll("text.zoom-outer-oval")
        // .attr("opacity", 0.1);
        .style("visibility", () => (currentLevel > 0 ? "visible" : "hidden"));

      svg
        .selectAll("circle")
        .attr("opacity", 1)
        .style("visibility", (d) => {
          return (d.data && d.data.level < currentLevel + 2) ||
            d.id == "exec" ||
            d.level
            ? "visible"
            : "hidden";
        });
      d3.selectAll("*[class*='border-circle_']").style("visibility", "hidden");
      console.log("current selected", currentSelected);
      if (currentSelected !== null) {
        d3.select(".border-circle_" + currentSelected).style(
          "visibility",
          "visible"
        );
      }

      svg.selectAll(".small-icons").attr("opacity", 0);

      svg
        .selectAll("text.outer-labels")
        .attr("opacity", 1)
        .style("visibility", (d) => {
          return currentLevel > 0 ? "hidden" : "visible";
        });

      svg
        .selectAll("text.header-labels")
        // .attr("opacity", 0)
        // .style('visibility', 'hidden');
        .style("visibility", (d) => {
          return currentLevel > 0 ? "hidden" : "visible";
        });

      svg
        .selectAll("text.arc-labels")
        .filter((d) => d.data.level === 1)
        .attr("opacity", 1)
        .style("visibility", "visible");

      svg
        .selectAll("text.horizontal-labels")
        .attr("opacity", 1)
        .style("visibility", "hidden");

      svg.selectAll("line").attr("stroke-opacity", 0);
      //.attr("stroke-opacity", d => (d.type === 'cct-coc' | d.type === 'cct-ct') ? 0 : 1)

      svg
        .selectAll("polygon")
        .attr("opacity", 1)
        .style("visibility", "visible");

      svg
        .selectAll("text.header-labels")
        .selectAll("[id^=CoC]")
        .attr("opacity", 0)
        .style("visibility", "visible");

      svg
        .selectAll("text.arc-labels")
        .selectAll("[id^=CoC]")
        .attr("opacity", 1)
        .style("visibility", "visible");

      svg
        .selectAll("text.horizontal-labels")
        .selectAll("[id^=CoC]")
        .attr("opacity", 0)
        .style("visibility", "hidden");

      svg
        .selectAll("text.small-horizontal-labels")
        // .attr("opacity", 0)
        .style("visibility", (d) => {
          return d.data.level == currentLevel + 1 ? "visible" : "hidden";
        });
    }

    function drawHexagons(nodeElement, data, options) {
      const width = options.width;
      const height = options.height;
      const pack = (data) =>
        d3.pack().size([width, height]).padding(3)(
          d3
            .hierarchy(data)
            .sum((d) => d.value)
            .sort((a, b) => b.value - a.value)
        );

      const root = pack(data);
      let focus = root;

      //   const nodeG = g
      //   .append("g")
      //   .attr("class", "nodesWrapper")
      //   .selectAll("g")
      //   .data(nodes)
      //   .join("g")
      //   .attr("class", (d) => d.type);

      // nodeG
      //   .filter((d) => d.type === "oval")
      //   .call(drag(simulation, link))
      //   .append("ellipse")
      //   .attr("id", (d) => d.id) // tag each entity by its unique ID
      //   .attr("class", (d) => d.groupId) //
      //   .attr("rx", (d) => radiusAccessor(d) * 1.25)
      //   .attr("ry", (d) => radiusAccessor(d) * 0.75)
      //   .attr("fill", "white")
      //   .attr("stroke", color(0))
      //   .attr("stroke-width", "2px");
      const node = nodeElement
        .append("g")
        //   .attr("class", "nodesWrapper")
        .selectAll("g")
        .data(root.descendants().slice(1))
        .join("g");

      node
        .append("circle")
        .attr("class", (d) => {
          return "border-circle_" + d.data.id;
        })
        .attr("stroke", (d) => color(d.depth))
        .attr("stroke-width", (d) => {
          return d.depth < 2 ? "2px" : "1px";
        })
        .attr("fill", "transparent")
        .attr("transform", (d) => `translate(${d.x - root.x},${d.y - root.y})`)
        .attr("r", (d) => {
          return d.depth < 2 ? d.r + 3 : d.r + 1;
        })
        .style("visibility", "hidden");

      node
        .append("circle")
        .attr("id", (d) => d.data.id)
        .attr("class", (d) => {
          // console.log('inner circle data', d)
          return d.data.groupId;
        })
        .attr(
          "fill",
          (d) => {
            if (
              (d.data.groupId === "corporate_governance") |
              (d.data.groupId === "communications")
            ) {
              return `url(#radial-gradient${d.depth + 1})`;
            } else {
              if (
                d.data.groupId === "CoC-global_people" &&
                (d.data.id === "EG-1-Expert" ||
                  d.data.id === "EG-2-Expert" ||
                  d.data.id === "EG-3-Expert")
              ) {
                return `url(#radial-gradient${d.depth + 1})`;
              } else {
                return `url(#radial-gradient${d.depth})`;
              }
            }
          }
          // (d.data.groupId === "corporate_governance") |
          // (d.data.groupId === "communications")
          //   ? `url(#radial-gradient${d.depth + 1})`
          //   : d.data.groupId === "CoC-global_people" && d.data.id === "EG-3-Expert" ?
          //   `url(#radial-gradient${d.depth + 1})` : `url(#radial-gradient${d.depth})`
        )
        .attr("transform", (d) => `translate(${d.x - root.x},${d.y - root.y})`)
        .attr("r", (d) => {
          return d.r;
        })
        .style("visibility", (d) => {
          return d.depth > 0 ? "hidden" : "visible";
        });

      function getSize(d) {
        if (d.depth === 0) {
          return 100;
        } else if (d.depth === 1) {
          return 20;
        } else if (d.depth === 2) {
          return 10;
        } else if (d.depth === 3) {
          return 5;
        } else {
          return 5;
        }
      }

      // parent label texts along paths
      let P = nodeElement
        .append("g")
        .attr("pointer-events", "none") // ensure text labels are not blocking circles from being hovered upon
        .selectAll("text")
        .data(
          root.descendants().filter((d) => d.children && d.children.length > 1)
        )
        .join("text")
        .attr("class", "arc-labels")
        .attr("id", (d) => d.data.id)
        .attr("font-weight", "bold")
        .attr("font-family", "Roboto")
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .style("visibility", (d) => (d.data.level === 1 ? "visible" : "hidden"))
        .append("textPath")
        .attr("font-size", (d) => {
          if (d.data.level === 1) {
            return "1em";
          } else if (d.data.level === 2) {
            return "0.4em";
          } else {
            return "0.2em";
          }
        })
        .attr("startOffset", "50%")
        .attr("xlink:href", function (d, i) {
          return `#arc${d.data.id}`;
        })
        .text((d) => d.data.name);

      P.each(function (d, i) {
        d3.select(this).call(crop, d.r);
      });

      // these are the FULL/TRUNCATED horizontal texts that appear initially for the 2nd-level bubbles
      nodeElement
        .append("g")
        .attr("pointer-events", "none") // ensure text labels are not blocking circles from being hovered upon
        .selectAll("text")
        .data(root.descendants().filter((d) => d.data.level > 2))
        .join("text")
        .attr("id", (d) => d.data.id)
        .attr("class", "horizontal-labels")
        .attr("transform", (d) => `translate(${d.x - root.x},${d.y - root.y})`)
        .attr("font-size", (d) =>
          d.r < 20 && d.data.truncated_name.length > 15 ? "0.4em" : "0.6em"
        )
        .attr("font-weight", "normal")
        .attr("dy", (d) => {
          if (d.data.name.length > 30) {
            return -2.2;
          } else if (d.data.name.length > 15) {
            return -2;
          } else {
            return -1;
          }
        })
        .attr("y", 0)
        //.attr("opacity", 1)
        // .attr("opacity", d => { return d.depth > 1 ? 0 : 1}) // hakuna
        .style("visibility", "hidden") //d => { return d.depth > 1 ? 'hidden' : 'visible'}) // hakuna
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .text((d) =>
          d.r < 10 ? "" : d.r < 20 ? d.data.truncated_name : d.data.name
        )
        .call(wrap, 26);

      // these are the FULL/TRUNCATED horizontal texts that appear for bubbles deeper than the 2nd-level
      nodeElement
        .append("g")
        .attr("pointer-events", "none") // ensure text labels are not blocking circles from being hovered upon
        .selectAll("text")
        // .data(root.descendants().filter(d => (d.data.level > 2 && !d.children)))
        .data(root.descendants().filter((d) => d.data.level > 2))
        .join("text")
        .attr("id", (d) => d.data.id)
        .attr("class", (d) => "zoom-" + d.data.level + "-horizontal-labels")
        .attr("transform", (d) => `translate(${d.x - root.x},${d.y - root.y})`)
        .attr("font-size", (d) => {
          if (d.data.level < 3) {
            return "0.4em";
          } else {
            return d.r >= 10 ? "0.25em" : "0.2em";
          }
        })
        .attr("font-weight", "normal")
        .attr("dy", (d) => {
          if (d.data.name.length > 30) {
            if (d.data.truncated_name.length > 19) {
              return -0.6;
            } else {
              if (d.data.truncated_name.indexOf(" ") !== -1) {
                return -0.5;
              }
              return 0;
            }
          } else if (d.data.name.length > 19) {
            return -0.6;
          } else {
            if (d.data.name.indexOf(" ") !== -1) {
              return 0;
            }
            return 0;
          }
        })
        .attr("y", 0)
        // .attr("opacity", 0)
        .style("visibility", "hidden") // hakuna
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .text((d) =>
          d.data.name.length > 30 ? d.data.truncated_name + "..." : d.data.name
        )
        .call(wrap, 17);

      // these are the FULL horizontal texts that appear on zoom
      nodeElement
        .append("g")
        .attr("pointer-events", "none") // ensure text labels are not blocking circles from being hovered upon
        .selectAll("text")
        // .data(root.descendants().filter(d => (d.data.level === 2 && !d.children)))
        .data(root.descendants().filter((d) => d.data.level === 2))
        .join("text")
        .attr("id", (d) => d.data.id)
        .attr("class", (d) => "small-horizontal-labels")
        .attr("transform", (d) => `translate(${d.x - root.x},${d.y - root.y})`)
        .attr("font-size", (d) => {
          // if (currentLevel === 2) {
          //   return "0.6em"
          // } else if (d.data.level < 3) {
          //   return "0.6em"
          // } else {
          return "0.5em";
        })
        .attr("font-weight", "normal")
        .attr("dy", (d) => {
          const string = getLabel(d);
          if (getLabel(d).length < 7) {
            return 0;
          } else if (getLabel(d).length < 14) {
            if (string.indexOf(" ") !== -1) {
              // It has only whitespace
              return -1;
            }
            return -0.5;
          } else if (getLabel(d).length < 25) {
            return -1.3;
          } else if (getLabel(d).length < 30) {
            return -1.7;
          } else if (getLabel(d).length < 35) {
            return -2;
          } else {
            return -3;
          }
        })
        .attr("y", 0)
        .attr("opacity", 0)
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .text((d) => {
          return getLabel(d);
        })
        .call(wrap, 20);

      nodeElement
        .append("g")
        .attr("pointer-events", "none") // ensure text labels are not blocking circles from being hovered upon
        .selectAll("circle")
        .data(
          root
            .descendants()
            .filter((d) => d.data.level > 1 || d.data.type == "inner-circle")
        )
        .join("circle")
        .attr("id", (d) => "small-icons-" + d.data.id)
        .attr("class", (d) => "small-icons")
        .attr(
          "transform",
          (d) =>
            `translate(${d.x - root.x + d.r / 2 + d.r / 4},${d.y - root.y - d.r / 3 - d.r / 8
            })`
        )
        .attr("fill", "white")
        .attr("r", (d) => d.r / 10)
        .attr("opacity", 0);
    }

    function toggleClass(elem, className) {
      if (elem.className.indexOf(className) !== -1) {
        elem.className = elem.className.replace(className, "");
      } else {
        elem.className = elem.className.replace(/\s+/g, " ") + " " + className;
      }

      return elem;
    }

    function toggleDisplay(elem) {
      const curDisplayStyle = elem.style.display;

      if (curDisplayStyle === "none" || curDisplayStyle === "") {
        elem.style.display = "block";
      } else {
        elem.style.display = "none";
      }
    }

    function toggleMenuDisplay(e) {
      const dropdown = e.currentTarget.parentNode;
      const menu = dropdown.querySelector(".menu");

      toggleClass(menu, "hide");
    }

    function handleOptionSelected(e, titleElem) {
      toggleClass(e.target.parentNode, "hide");

      const id = e.target.id;
      const newValue = e.target.textContent + " ";

      titleElem.textContent = newValue;

      //trigger custom event
      titleElem.dispatchEvent(new Event("change"));
    }

    function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      };
    }

    function getTranslation(transform) {
      // Create a dummy g for calculation purposes only. This will never
      // be appended to the DOM and will be discarded once this function
      // returns.
      var g = document.createElementNS("http://www.w3.org/2000/svg", "g");

      // Set the transform attribute to the provided string value.
      g.setAttributeNS(null, "transform", transform);

      // consolidate the SVGTransformList containing all transformations
      // to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
      // its SVGMatrix.
      var matrix = g.transform.baseVal.consolidate().matrix;

      // As per definition values e and f are the ones for the translation.
      return [matrix.e, matrix.f, matrix.a, matrix.d];
    }

    function describeArc(x, y, radius, startAngle, endAngle) {
      var start = polarToCartesian(x, y, radius, endAngle);
      var end = polarToCartesian(x, y, radius, startAngle);
      var arcSweep = endAngle - startAngle <= 180 ? "0" : "1";
      var d = [
        "M",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        1,
        1,
        end.x,
        end.y,
      ].join(" ");
      return d;
    }

    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy")),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em");

        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
      });
    }

    function crop(text, circleRadius) {
      text.each(function () {
        var text = d3.select(this);
        while (text.node().getComputedTextLength() > circleRadius * 3.5) {
          text.text(text.text().slice(0, -4) + "...");
        }
      });
    }

    function searchInfoBox(d, keyword) {
      d3.select(".panel").style("visibility", "hidden");
      d3.select(".panel-search").style("visibility", "visible");

      let name = d.name ? d.name : d.data.name;
      if (name === prevName || prevName === undefined) {
        clicked = true;
      }
      const person = findPersonByName(keyword);

      let panelWrapper = d3.select(".panel-search");
      panelWrapper.select(".panel-header").text(keyword);

      panelWrapper.select(".panel-header2-title").text("Lead: ");
      panelWrapper.select(".panel-header2").text(name);
      panelWrapper
        .select(".panel-bio-value")
        .text(person && person.bio ? person.bio : "");
      panelWrapper.select(".panel-competence-value").text("");
      panelWrapper
        .select(".panel-contact-value span.email")
        .text(person && person.email ? person.email : "");
      panelWrapper
        .select(".panel-contact-value span.phone")
        .text(person && person.phone ? person.phone : "");
      // panelWrapper.select('#parent-node').text(parentName + "(N.N.)");
      // panelWrapper.select('#self-node').text(name+"(" + headName +")");
      // document.querySelector('#team-name').innerHTML = name;
      panelWrapper.select(".panel-roles-value").selectAll("div").remove();
      if (person && person.roles) {
        panelWrapper
          .select(".panel-roles-value")
          .selectAll("div")
          .data(person.roles)
          .enter()
          .append("div")
          .attr("class", "test")
          .text(function (role) {
            let detail = role.role;
            var matched_node = findCircleByPersonRoleId(role.node_id);
            console.log("aiiii ", role);
            let suffix = role.ad_interim === "true" ? " a.i." : "";
            return (
              matched_node.name + ": " + capitalize(role.role || "") + suffix
            );
          });
      } else {
      }

      if ((clicked === false) & (prevId === d.id)) {
        // panelWrapper.select('.report-list table').remove()
        d3.select(".panel-search").style("visibility", "hidden");
      } else {
        panelWrapper.select(".report-list table").remove();
        d3.select(".panel-search").style("visibility", "visible");
      }
      prevId = d.id;
      // d3.event.stopPropagation();
    }

    function findPersonByRole(node_id, roleToCheck) {
      return json_data.people.filter((person) => {
        if (person.roles) {
          const filteredRoles = person.roles.find(
            (role) =>
              role.node_id === node_id &&
              role.role &&
              role.role.toLowerCase() === roleToCheck
          );

          if (filteredRoles && filteredRoles.node_id) {
            return person;
          } else {
            return null;
          }
        } else return null;
      });
    }

    function findPersonByName(node_name) {
      return json_data.people.find((person) => person.name === node_name);
    }

    function findPersonById(node_id) {
      return json_data.people.find((person) => person.id === node_id);
    }

    function findCircleByPersonRoleId(node_id) {
      return data_flat.find((item) => item.id === node_id);
    }

    function capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    }

    function getObjectType(node_id) {
      // if(node_id === 'exec')
      return node_id.split("-")[0];
    }

    function clickCCT(event, d) {
      d3.select(".panel-search").style("visibility", "hidden");
      d3.select(".panel").style("visibility", "hidden");

      let name = d.name ? d.name : d.data.name;
      const objectId = d.id ? d.id : d.data.id;
      if (name === prevName || prevName === undefined) {
        clicked = true;
      }

      // get person data by object id from json
      const personData = findPersonByRole(objectId, "lead");
      // console.log('personData', personData)
      let headName = "";
      if (personData && personData.length > 0) {
        personData.map((person, i) => {
          // console.log(person.name)
          if (i + 1 < personData.length) headName += person.name + ", ";
          else headName += person.name;
        });
      } else {
        // console.log('has no persondata', typeof personData)
        headName = "N.N.";
      }

      const purpose =
        typeof d.description !== undefined
          ? d.description
          : d.data.description
            ? d.data.description
            : "N.N.";
      let parentNodeId = d.parent ? d.parent.data.id : "exec";
      const parentName = d.parent ? d.parent.data.name : "Executive Board";

      // Get parent head name
      const roleToCheck = (d.id && d.id.substring(0, 3) == "CoC") ? "lead" : "lead";
      const parentPersonData = findPersonByRole(parentNodeId, roleToCheck);

      let parentHeadName = "";
      if (parentPersonData && parentPersonData.length > 0) {
        parentPersonData.map((person, i) => {
          // console.log(person.name)
          if (i + 1 < parentPersonData.length)
            parentHeadName += person.name + ", ";
          else parentHeadName += person.name;
        });
      } else {
        // console.log('has no persondata', typeof personData)
        parentHeadName = "N.N.";
      }

      let panelWrapper = d3.select(".panel");
      d3.selectAll(".children-node div").remove();
      d3.selectAll(".child-pointer").style("visibility", "hidden");

      if ((d.id && d.id.substring(0, 3) == "CoC") || d.parent) {
        // if (d.id == "CoC-home") {
        if (
          (d.id && d.id.substring(0, 3) == "CoC") ||
          (d.id && d.id.substring(0, 3) == "CCT")
        ) {
          if (
            personData &&
            personData.length &&
            personData.length > 1 &&
            headName !== "N.N."
          ) {
            panelWrapper.select(".panel-header2-title").text("Heads: ");
          } else {
            panelWrapper.select(".panel-header2-title").text("Head: ");
          }
        } else {
          panelWrapper.select(".panel-header2-title").text("");
        }

        panelWrapper.select(".panel-header2").text(headName);
        panelWrapper.select(".panel-purpose-value").text(purpose);
        panelWrapper
          .select(".parent-node")
          .text(parentName + " (" + parentHeadName + ")");
        panelWrapper.select(".self-node").text(name + " (" + headName + ")");
        // document.querySelector('#speaker-wrapper').style.display = 'flex';
        if (!d.data) {
          panelWrapper
            .select(".panel-header")
            .text(name + " (Center of Competence)");
          if (d.children) {
            panelWrapper
              .select(".child-pointer")
              .style("visibility", "visible");
            panelWrapper
              .select(".children-node")
              .selectAll("div")
              .data(d.children)
              .enter()
              .append("div")
              .attr("class", "test")
              .text(function (d) {
                const personArr = findPersonByRole(d.id, "lead");

                let each_person = { name: "N.N" };
                let adInterimSuffix = "";
                if (personArr && personArr.length) {
                  each_person = personArr[0];
                  if (personArr[0].roles && personArr[0].roles.length) {
                    personArr[0].roles.forEach((item) => {
                      if (item.ad_interim === "true") {
                        adInterimSuffix = " a.i.";
                      }
                    });
                  }
                }
                return each_person
                  ? "|_" + d.name + ` (${each_person.name + adInterimSuffix})`
                  : "|_" + d.name + " (N.N." + adInterimSuffix + ")";
              });
          } else {
            panelWrapper.select(".child-pointer").style("visibility", "hidden");
            const teams = getPeopleTeamForNode(json_data.people, d.id);
            console.log(teams, 'teams')
            if (teams && teams.length) {
              // Construct the team table
              createTable(
                teams,
                ["people_name", "role", "location"],
                ["Name", "Role", "Country"],
                "team-table-div"
              );
              console.log("teams", d, teams);
            } else {
              removeTeamsTable();
            }
          }
        } else {
          panelWrapper.select(".panel-header").text(name);
          if (d.data.children) {
            panelWrapper
              .select(".child-pointer")
              .style("visibility", "visible");
            panelWrapper
              .select(".children-node")
              .selectAll("div")
              .data(d.data.children)
              .enter()
              .append("div")
              .attr("class", "test")
              .text(function (child) {
                const personArr = findPersonByRole(child.id, "lead");

                let each_person = { name: "N.N" };
                let adInterimSuffix = "";
                if (personArr && personArr.length) {
                  each_person = personArr[0];
                  if (personArr[0].roles && personArr[0].roles.length) {
                    personArr[0].roles.forEach((item) => {
                      if (item.ad_interim === "true") {
                        adInterimSuffix = " a.i.";
                      }
                    });
                  }
                }
                return each_person
                  ? "|_" +
                  child.name +
                  ` (${each_person.name + adInterimSuffix})`
                  : "|_" + child.name + " (N.N." + adInterimSuffix + ")";
              });
          } else {
            panelWrapper.select(".child-pointer").style("visibility", "hidden");
          }
          // d3.select('.panel').style('visibility', 'hidden')
        }

        if ((clicked === false) & (prevId === d.id)) {
          panelWrapper.select("#table table").remove();
          d3.select(".panel").style("visibility", "hidden");
        } else {
          d3.select(".panel").style("visibility", "visible");
        }
      } else {
        // document.querySelector('.supervisor').innerHTML = "";
        // panelWrapper.select('.report-list ul').remove();
        panelWrapper.select(".panel-purpose-value").innerHTML = "";
        panelWrapper.select(".panel-header2-title").text("");
      }

      if (d.id && d.type == "inner-circle") {
        //|| d.parent) {

        let panelWrapper = d3.select(".panel");
        panelWrapper.select(".panel-header").text(name);

        // panelWrapper.select('.panel-header').text(name+'(Center of Competence)')
        panelWrapper.select(".panel-header2").text(" " + headName);
        panelWrapper.select(".panel-header2").text(headName);
        panelWrapper.select(".panel-purpose-value").text(purpose);
        panelWrapper.select(".parent-node").text(parentName + " (N.N.)");
        panelWrapper.select(".self-node").text(name + " (" + headName + ")");

        if (d.children) {
          panelWrapper.select(".child-pointer").style("visibility", "visible");
          panelWrapper
            .select(".children-node")
            .selectAll("div")
            .data(d.children)
            .enter()
            .append("div")
            .attr("class", "test")
            .text(function (d) {
              const personArr = findPersonByRole(d.id, "lead");

              let each_person = { name: "N.N" };
              if (personArr && personArr.length) {
                each_person = personArr[0];
              }
              return each_person
                ? "|_" + d.name + ` (${each_person.name})`
                : "|_" + d.name + " (N.N.)";
            });
        } else {
          panelWrapper.select(".child-pointer").style("visibility", "hidden");
        }

        if (d.team && d.id !== "CoC-home") {
          // document.querySelector('#team-name').innerHTML = d.team.name;
          // document.querySelector('#team-lead').innerHTML = 'N.N';
          // panelWrapper.select('.panel-speaker .dropdown .title').html('Speaker <i className="arrow down"></i>')

          d3.select(".panel").style("visibility", "hidden");

          if ((clicked === false) & (prevId === d.id)) {
            d3.select(".panel").style("visibility", "hidden");
          } else {
            d3.select(".panel").style("visibility", "visible");
          }
        } else {
        }

        prevId = d.id;
        event.stopPropagation();
      } else if (d.id && d.id.includes("CCT")) {

        createCctInfobox(d);

        prevId = d.id;
        event.stopPropagation();
      }
      const nodeData = d.data || d || {};
      if ((!nodeData.children || (nodeData.children && !nodeData.children.length))) {
        const teams = getPeopleTeamForNode(json_data.people, nodeData.id);
        if (teams && teams.length) {
          // Construct the team table
          console.log(teams, 'teams')
          createTable(
            teams,
            ["people_name", "role", "location"],
            ["Name", "Role", "Country"],
            "team-table-div"
          );
        } else {
          removeTeamsTable();
        }
      } else {
        removeTeamsTable();
      }
      prevName = name;
    }

    // // // // // //   Autocomplete // // // // //
    var keywords = [];
    data_flat.map((node) => keywords.push(node.name));
    json_data.people.map((person) => keywords.push(person.name));

    var items = keywords.map(function (n) {
      return { label: n };
    });
    var allowedChars = new RegExp(/^[a-zA-Z\s]+$/);

    function charsAllowed(value) {
      return allowedChars.test(value);
    }

    autocomplete({
      input: document.querySelector(".search-form"),
      minLength: 1,
      onSelect: function (item, inputfield) {
        inputfield.value = item.label;
      },
      fetch: function (text, callback) {
        var match = text.toLowerCase();
        callback(
          items.filter(function (n) {
            return n.label.toLowerCase().indexOf(match) !== -1;
          })
        );
      },
      render: function (item, value) {
        var itemElement = document.createElement("div");
        if (charsAllowed(value)) {
          var regex = new RegExp(value, "gi");
          var inner = item.label.replace(regex, function (match) {
            return "<strong>" + match + "</strong>";
          });
          itemElement.innerHTML = inner;
        } else {
          itemElement.textContent = item.label;
        }
        return itemElement;
      },
      emptyMsg: "No matched found",
      customize: function (input, inputRect, container, maxHeight) {
        if (maxHeight < 100) {
          container.style.top = "";
          container.style.bottom =
            window.innerHeight - inputRect.bottom + input.offsetHeight + "px";
          container.style.maxHeight = "140px";
        }
      },
    });

    document.querySelector(".search-form").focus();
  };

  const classes = useStyles();

  const createTable = (objectArray, fields, fieldTitles, divId) => {    
    let columns = []
    let data = []

    objectArray.forEach((object) => {
      let newRow = {}
      fields.forEach((field) => {
        newRow[field] = object[field]
      });
      data.push(newRow)
    })

    fieldTitles.forEach((title, i) => {
      let newField = {
        title: fieldTitles[i],
        field: fields[i]
      }
      columns.push(newField)
    }) 

    if(divId === "people-table-div"){
      setPeopleTableColumns(columns)
      setPeopleTableData(data)
    }

    if(divId === "team-table-div") {
      setTeamsTableColumns(columns)
      setTeamsTableData(data)
    }
  };

  const handleShowCCT = () => {
    setShowCCT(!showCCT)
    
    showCCT
      ? toggleCCT({ id: "cct_none" })
      : toggleCCT({ id: "cct_all" });
  } 

  const removePeopleTable = (divId) => {
    setPeopleTableData([])
    setPeopleTableColumns([])
  }

  const removeTeamsTable = (divId) => {
    setTeamsTableData([])
    setTeamsTableColumns([])
  }

  return (
    <div className="wrapper">
      <div className="sidebar">
      </div>
      <div className={classes.chartContainer}>
        <div
          className="chart"
          style={{
            position: "relative",
            marginBottom: "40px",
          }}
        />
      </div>
      <div className="legend"></div>

      <div className="side-right">
        <input
          type="text"
          className="search-form border-radius-32 box-shadow"
          placeholder="Search for Person/Teams"></input>
        <div className="button-panel" onClick={() => handleShowCCT()}>
          <span className="btn-legend"> {showCCT ? 'HIDE CROSS-COMPETENCE TEAMS' :'SHOW CROSS-COMPETENCE TEAMS'}</span>
        </div>

        {/* Bubble info box panel */}
        <div className="panel border-radius-32">
          <h4 className="panel-header"></h4>
          <h4>
            <span className="panel-header2-title panel-bio"></span>
            <span className="panel-header2 bold dark-grey"></span>
          </h4>
          <h4 className="panel-bio">
            Purpose:&ensp;
            <span className="panel-purpose-value"></span>
          </h4>

          <div className="Rtable-cell-2">
            <span className="parent-node"></span>
          </div>
          <div style={{ paddingLeft: "15px" }}> | </div>
          <div className="Rtable-cell-2">
            <span className="self-node"></span>
          </div>
          <div
            className="child-pointer"
            style={{ paddingLeft: "15px", visibility: "hidden" }}>
            {" "}
            |{" "}
          </div>
          <div className="Rtable-cell-2">
            <span className="children-node"></span>
          </div>
          {teamstableColumns.length > 1 && teamsTableData.length > 1 && 
            <div style={{ maxWidth: '100%' }}>
              <MaterialTable
                columns={teamstableColumns}
                data={teamsTableData}
                options={getInfoboxTableOptions}
              />
            </div>
          }
        </div>

        {/* CCT info box panel */}
        <div className="panel-box-CCT border-radius-32">
          <h4 className="panel-header">
            <span></span> (Cross-Competence Team)
          </h4>
          <h4>
            <span className="panel-header2-title panel-bio"></span>
            <span className="panel-header2 bold dark-grey">
              {" "}
              <span></span>
            </span>
          </h4>
          {/* <h4 className="panel-header2 bold">
            Speaker: <span></span>
          </h4> */}

          <h4 className="panel-bio">
            Purpose:&ensp;
            <span className="panel-bio-value"></span>
          </h4>

          <div className="Rtable-cell-2">
            <span className="parent-node"></span>
          </div>
          <div style={{ paddingLeft: "15px" }}> | </div>
          <div className="Rtable-cell-2">
            <span className="self-node"></span>
          </div>
          <div
            className="child-pointer"
            style={{ paddingLeft: "15px", visibility: "hidden" }}>
            {" "}
            |{" "}
          </div>
          <div className="Rtable-cell-2">
            <span className="children-node"></span>
          </div>
          { peopletableColumns.length > 1 && peopleTableData.length > 1 && 
            <div style={{ maxWidth: '100%' }}>
              <MaterialTable
                columns={peopletableColumns}
                data={peopleTableData}
                options={getInfoboxTableOptions}
              />
            </div>
          }
        </div>

        {/* Search info box panel */}
        <div className="panel-search border-radius-32">
          <div className="panel-header"></div>
          <div className="panel-header-title panel-bio"></div>
          <div className="panel-header2 dark-grey"></div>

          {/*<h5 className="panel-bio">
            Short Bio:&ensp;
            <span className="panel-bio-value"></span>
          </h5>

          <h5 className="panel-competence">
            Competencies:&ensp;
            <span className="panel-competence-value"></span>
          </h5>*/}

          <h5 className="panel-roles">
            Roles:&ensp;
            <span className="panel-roles-value"></span>
          </h5>

          {/*<h5 className="panel-contact">
            Contact Info:
            <div className="panel-contact-value">
              Email: <span className="email"></span>
            </div>
            <div className="panel-contact-value">
              Phone: <span className="phone"></span>
            </div>
          </h5>*/}
        </div>
        <div
          className="panel-wrapperCCT border-radius-32"
          style={{ display: "none" }}>
          CCT Overview
          <p>
            <label for="cct_all">
              <input type="radio" name="cct_radio" id="cct_all" checked />
              <span>Show All</span>
            </label>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}
