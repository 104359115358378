import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import _ from "lodash";

class UserDataService {
  db;

  constructor() {
    this.db = firebase.firestore();
  }

  getUserRole = (userId) => {
    //   generally it's better to use uid for docId
    return this.db.collection("user-roles").where("user_id", "==", userId)
      .get()
      .then((docList) => {
        let items = [];
        docList.docs.forEach((doc) => {
          // console.log(doc.data());
          items.push(doc.data());
        });
        if (items && items.length) {
          return items[0];
        } else {
          return {};
        }
      }).catch((error) => error);
  };
}

export default new UserDataService();
