import React, {useEffect, useState} from "react";
import * as d3 from "d3";
import _ from "lodash";
import NodesDataService from "../../../services/firebase/NodesDataService";
import PeopleDataService from "../../../services/firebase/PeopleDataService";
import "./index.style.scss";

let path = null;

let margin = {top: 25, right: 90, bottom: 30, left: 90};
//let width4OrgChart = 1660 - margin.left - margin.right;
// let height4OrgChart = 1500 - margin.top - margin.bottom;
let width4OrgChart = window.innerWidth * 0.9;
let height4OrgChart = window.innerHeight;

export default function OrganizationChartComponent(props) {
  useEffect(() => {
    let mounted = true;
    let nodes = [];
    let people = [];
    // Getting nodes from firebase
    NodesDataService.getAll().then((items) => {
      if (mounted) {
        nodes = items;
        // Getting people from firebase
        PeopleDataService.getAll().then((items) => {
          if (mounted) {
            people = items;
            orgChart(nodes, people);
          }
        });
      }
    });
    return () => (mounted = false);
  }, []);

  const orgChart = (nodes, people) => {
    // append the svg object to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    var org_svg = d3
      .select(".org-chart")
      .append("svg")
      .attr("width", width4OrgChart + margin.right + margin.left)
      .attr("height", height4OrgChart + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    var i = 0,
      duration = 750,
      root;

    // declares a tree layout and assigns the size
    var treemap = d3.tree().size([width4OrgChart, height4OrgChart]);

    // Assigns parent, children, height, depth

    // get home node
    const filteredNodes = _.filter(nodes, (item) => item.id === "CoC-home");
    let homeNodeTree = {};
    if (filteredNodes && filteredNodes.length) {
      homeNodeTree = filteredNodes[0];
    }
    root = d3.hierarchy(homeNodeTree, function (d) {
      return d.children;
    });
    root.x0 = height4OrgChart / 2;
    root.y0 = 0;
    // console.log(root);
    // Collapse after the second level
    root.children && root.children.forEach(collapse);

    update(root);

    // Collapse the node and all it's children
    function collapse(d) {
      if (d.children) {
        d._children = d.children;
        d._children.forEach(collapse);
        d.children = null;
      }
    }

    function findPersonByRole(node_id) {
      return people.find((person) =>
      person.roles && person.roles.find(
          (role) =>
            role.node_id === node_id && role.role.toLowerCase() === "lead"
        )
      );
    }

    function update(source) {
      // Assigns the x and y position for the nodes
      var treeData = treemap(root);

      // Compute the new tree layout.
      var nodes = treeData.descendants(),
        links = treeData.descendants().slice(1);
      // console.log(nodes);

      // Normalize for fixed-depth.
      nodes.forEach(function (d) {
        d.y = d.depth * 120;
      });

      // ****************** Nodes section ***************************

      // Update the nodes...
      var node = org_svg.selectAll("g.node").data(nodes, function (d) {
        return d.id || (d.id = ++i);
      });

      // Enter any new modes at the parent's previous position.
      var nodeEnter = node
        .enter()
        .append("g")
        .attr("class", "node")
        .attr("transform", function (d) {
          // console.log('source', source)
          return "translate(" + source.x0 + "," + source.y0 + ")";
        })
        .on("click", click);

      // Add rectangle for the nodes
      nodeEnter
        .append("rect")
        .attr("class", "node")
        .attr("x", function (d) {
          return -margin.left / 2;
        })
        .attr("y", function (d) {
          return -margin.top;
        })
        .style("fill", function (d) {
          return d._children ? "forestgreen" : "#fff";
        });

      // Add labels for the nodes
      nodeEnter
        .append("text")
        .attr("dy", ".35em")
        .attr("dx", 5)
        .attr("x", function (d) {
          return 0; //d.children || d._children ? 25 : -25;
        })
        .attr("y", function (d) {
          // return d.data.name.length < 12 ? 5 : 0;
          if (d.data.name.length < 12) return 5;
          else if (d.data.name.length <= 22) return 0;
          else if (d.data.name.length < 29) return -10;
          else if (d.data.name.length < 37) return -10;
          else return -15;
        })
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .style("font-family", "Helvetica")
        .text(function (d) {
          return d.data.name; //+ d.data.name.length;
          //return d.data.name.length > 10 ? d.data.name.slice(0, 10) + '...' : d.data.name
        })
        .call(wrap, 85);

      // Add rectangle for the nodes name
      nodeEnter
        .append("rect")
        .attr("class", "node-name")
        .attr("x", function (d) {
          return -margin.left / 2;
        })
        .attr("y", function (d) {
          return -margin.top + 60;
        })
        .style("fill", function (d) {
          return "#fff"; //d._children ? "navy" : "#fff";
        });

      // Add labels for the nodes name
      nodeEnter
        .append("text")
        .attr("dy", ".35em")
        .attr("dx", 5)
        .attr("x", function (d) {
          return 0; //d.children || d._children ? 25 : -25;
        })
        .attr("y", function (d) {
          // return d.data.name.length < 12 ? 5 : 0;
          let deltaY = 50;
          const person = findPersonByRole(d.data.id);
          const text = person ? person.name : "N.N."
          const wrapped = isWrap(text, 85, 'normal 12px Helvetica');
          console.log('wrapped ', wrapped)
          if (wrapped) {
            // It has only whitespace
            return deltaY - 7;
          } else {
            return deltaY;
          }
          // return deltaY;
          // if(d.data.name.length < 12)
          //   return deltaY+5;
          // else if(d.data.name.length <= 22)
          //   return deltaY+0
          // else if(d.data.name.length < 29)
          //  return deltaY-10
          // else if(d.data.name.length < 37)
          //  return deltaY-10
          // else
          //   return deltaY-15
        })
        .attr("text-anchor", "middle")
        .style("font-family", "Helvetica")
        .text(function (d) {
          const person = findPersonByRole(d.data.id);

          console.log("name rect ", person);
          return person ? person.name : "N.N."; //d.data.name //+ d.data.name.length;
          //return d.data.name.length > 10 ? d.data.name.slice(0, 10) + '...' : d.data.name
        })
        .call(wrap, 85);

      // UPDATE
      var nodeUpdate = nodeEnter.merge(node);

      // Transition to the proper position for the node
      nodeUpdate
        .transition()
        .duration(duration)
        .attr("transform", function (d) {
          return "translate(" + d.x + "," + d.y + ")";
        });

      // Update the node attributes and style
      nodeUpdate
        .select("rect.node")
        // .attr('r', 10)
        .style("fill", function (d) {
          return d._children ? "forestgreen" : "#fff";
        })
        .attr("cursor", "pointer");

      nodeUpdate.select("text").attr("fill", function (d) {
        // console.log(d)
        if (d.children == null && d._children) return "#fff";
        else if (d.children && d._children == null) return "#333";
        else return "#333";
      });

      // Remove any exiting nodes
      var nodeExit = node
        .exit()
        .transition()
        .duration(duration)
        .attr("transform", function (d) {
          return "translate(" + source.x + "," + source.y + ")";
        })
        .remove();

      // On exit reduce the node circles size to 0
      // nodeExit.select('circle')
      //   .attr('r', 1e-6);

      // On exit reduce the opacity of text labels
      nodeExit.select("text").style("fill-opacity", 1e-6);

      // ****************** links section ***************************

      // Update the links...
      var link = org_svg.selectAll("path.link").data(links, function (d) {
        return d.id;
      });

      // Enter any new links at the parent's previous position.
      var linkEnter = link
        .enter()
        .insert("path", "g")
        .attr("class", "link")
        .attr("d", function (d) {
          var o = {x: source.x0, y: source.y0};
          return diagonal(o, o);
        });

      // UPDATE
      var linkUpdate = linkEnter.merge(link);

      // Transition back to the parent element position
      linkUpdate
        .transition()
        .duration(duration)
        .attr("d", function (d) {
          return diagonal(d, d.parent);
        });

      // Remove any exiting links
      var linkExit = link
        .exit()
        .transition()
        .duration(duration)
        .attr("d", function (d) {
          var o = {x: source.x, y: source.y};
          return diagonal(o, o);
        })
        .remove();

      // Store the old positions for transition.
      nodes.forEach(function (d) {
        d.x0 = d.x;
        d.y0 = d.y;
      });

      // Creates a line path from parent to the child nodes
      function diagonal(s, d) {
        // path = `M ${s.x} ${s.y}
        //     C ${(s.x + d.x) / 2} ${s.y},
        //       ${(s.x + d.x) / 2} ${d.y},
        //       ${d.x} ${d.y}`
        path = `M ${d.x}, ${d.y} v 80 H ${s.x} V ${s.y}`;

        return path;
      }

      // Toggle children on click.
      function click(event, d) {
        if (d.children) {
          d._children = d.children;
          d.children = null;
        } else {
          d.children = d._children;
          d._children = null;
        }
        update(d);
      }

      function wrap(text, width) {
       
        text.each(function () {
          var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            y = text.attr("y"),
            dy = parseFloat(text.attr("dy")),
            tspan = text
              .text(null)
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", dy + "em");

          while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width) {
              line.pop();
              tspan.text(line.join(" "));
              line = [word];
              tspan = text
                .append("tspan")
                .attr("x", 5)
                .attr("y", y)
                .attr("dy", ++lineNumber * lineHeight + dy + "em")
                .text(word);
            }
          }
        
        });
      }

      function isWrap(string, width, font) {
        console.log('text', string);

        var canvas = isWrap.canvas || (isWrap.canvas = document.createElement("canvas"));
        var context = canvas.getContext("2d");
        context.font = font;
        var metrics = context.measureText(string);
        return metrics.width > width ? true : false;
      }
    }
  };

  return (
    <div className="wrapper">
      <div className="org-chart"></div>
      <tspan class="wrap4tspan"></tspan>
    </div>
  );
}
