import React from "react";
import { authRoles } from "../../auth/authRoles";
import Homepage from "./Homepage";

const homepageRoutes = [
  {
    path: "/homepage",
    component: Homepage,
    auth: authRoles.admin
  }
];

export default homepageRoutes;
