import React from "react";
import {adInterimLookups} from "../helpers/DataTable";
import {getNodeIdLookupV2} from "../helpers/NodeDataTable";
import {tableIcons} from "./ReusableComponents/DatatableIconsComponent";
import {ChildTableComponent} from ".";

export default function RolesTableComponent(props) {
  const {rowData, nodes, roles, handleRolesChange} = props;
  const nodeIdLookups = getNodeIdLookupV2(nodes);

  const columns = [
    // {
    //   title: "Id",
    //   field: "id",
    //   validate: (rowData) => (rowData.id === "" ? "Id cannot be empty" : ""),
    //   sortable: true,
    //   editable: "onAdd",
    // },
    {
      title: "Circle",
      field: "node_id",
      validate: (rowData) =>
        rowData.nodeId === "" ? "Circle Id cannot be empty" : "",
      sortable: true,
      editable: "onAdd",
      lookup: nodeIdLookups,
    },
    {
      title: "Capacity",
      field: "capacity",
      validate: (rowData) =>
        rowData.type === "" ? "Capacity cannot be empty" : "",
      sortable: true,
      hidden: true,
    },
    {
      title: "Ad Interim",
      field: "ad_interim",
      editable: "onAdd",
      validate: (rowData) =>
        rowData.target === "" ? "Ad Interim cannot be empty" : "",
      sortable: true,
      lookup: adInterimLookups,
    },
    {
      title: "Role",
      field: "role",
      sortable: true,
    },
  ];

  return (
    <ChildTableComponent
      columns={columns}
      data={roles}
      parentRecord={rowData}
      tableHeader={`Roles for ${rowData.name}`}
      tableIcons={tableIcons}
      handleChange={handleRolesChange}
    />
  );
}
