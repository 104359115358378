import {authRoles} from "auth/authRoles";
import TableContainer from "./TableContainer";

const tableContainerRoutes = [
  {
    path: "/data",
    component: TableContainer,
    auth: authRoles.admin,
  },
];

export default tableContainerRoutes;
