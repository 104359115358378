import _ from "lodash";
import {getNodeIdLookupV2} from "./NodeDataTable";

export const getLinkColumns = (nodes) => {
  const nodeIdLookups = getNodeIdLookupV2(nodes);
  const columns = [
    {
      title: "Source",
      field: "source",
      validate: (rowData) =>
        rowData.source === "" ? "Source cannot be empty" : "",
      sortable: true,
      editable: "onAdd",
      lookup: nodeIdLookups,
    },
    {
      title: "Type",
      field: "type",
      validate: (rowData) =>
        rowData.type === "" ? "Type cannot be empty" : "",
      sortable: true,
      lookup: linkTypeLookups,
    },
    {
      title: "Target",
      field: "target",
      editable: "onAdd",
      validate: (rowData) =>
        rowData.target === "" ? "Target cannot be empty" : "",
      sortable: true,
      lookup: nodeIdLookups,
    },
    // {
    //   title: "X",
    //   field: "x",
    // },
    // {
    //   title: "Y",
    //   field: "y",
    // },
  ];

  return columns;
};

export const formatLinkData = (data) => {
  const clonedData = _.map(data, (item) => {
    if (item.source && item.source.id) {
      item.source = item.source.id;
    }
    if (item.target && item.target.id) {
      item.target = item.target.id;
    }
    if (item.index) {
      delete item["index"];
    }
    item = _.pick(item, validLinkAttributes);
    return item;
  });
  return clonedData;
};

export const linkTypeLookups = {
  inner: "inner",
  outer: "outer",
  "outer-oval": "outer-oval",
  "cct-coc": "cct-coc",
  "cct-ct": "cct-ct",
};

export const validLinkAttributes = ["id", "source", "target", "type", "x", "y"];
