import _ from "lodash";
import Swal from "sweetalert2";
import {makeStyles} from "@material-ui/core/styles";
import {ColorCodes} from "./Enums";

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "100vh",
  },
  viewerSettings: {
    overflow: "auto",
    // height: "50vh",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  grid: {
    marginBottom: "10px",
    marginRight: "10px",
  },
  title: {
    fontWeight: "bold",
  },
  headers: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    verticalAlign: "middle",
  },
  headerText: {
    marginLeft: "10px",
    fontWeight: "bold",
  },
  button: {
    float: "right",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.text,
      color: theme.palette.primary.main,
    },
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  topBar: {
    marginBottom: "24px",
  },
  loader: {
    position: "fixed",
    zIndex: 999,
    height: "2em",
    width: "2em",
    overflow: "show",
    margin: "auto",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const compareArrays = (oldData, newData) => {
  const clonedOldData = _.map(oldData, (item) => _.omit(item, ["tableData"]));
  const clonedNewData = _.map(newData, (item) => _.omit(item, ["tableData"]));

  const isDataChanged =
    JSON.stringify(clonedOldData) !== JSON.stringify(clonedNewData);
  return isDataChanged;
};

export const formatTeamData = (data) => {
  const clonedData = _.map(data, (item) => {
    item = _.pick(item, validTeamAttributes);
    return item;
  });
  return clonedData;
};

export const formatSupervisorData = (data) => {
  const clonedData = _.map(data, (item) => {
    item = _.pick(item, validSupervisorAttributes);
    return item;
  });
  return clonedData;
};

export const formatRoleData = (data) => {
  const clonedData = _.map(data, (item) => {
    item = _.pick(item, validRoleAttributes);
    return item;
  });
  return clonedData;
};

export const getEditableComponent = (data, handleChange) => {
  return {
    onBulkUpdate: (changes) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const dataUpdate = [...data];
          let changedItems = [];
          Object.keys(changes).forEach((i) => {
            changedItems.push(changes[i]);
          });

          changedItems &&
            changedItems.forEach((item) => {
              const index = item.oldData.tableData.id;
              dataUpdate[index] = item.newData;
            });
          handleChange([...dataUpdate]);
          resolve();
        }, 1000);
      }),
    onRowAddCancelled: (rowData) => console.log("Row adding cancelled"),
    onRowUpdateCancelled: (rowData) => console.log("Row editing cancelled"),
    onRowAdd: (newData) =>
      new Promise((resolve, reject) => {
        if (Object.keys(newData).length === 0) {
          Swal.fire({
            icon: "error",
            title: "Sorry...",
            text: "An empty row can not be added to the table",
          });
          reject();
        } else {
          setTimeout(() => {
            handleChange([...data, newData]);
            resolve();
          }, 2000);
        }
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          handleChange([...dataUpdate]);
          resolve();
        }, 2000);
      }),
    onRowDelete: (oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const dataDelete = [...data];
          const index = oldData.tableData.id;
          dataDelete.splice(index, 1);
          const id = oldData.id || oldData.source + "-" + oldData.target;
          handleChange([...dataDelete], id, "delete");

          resolve();
        }, 2000);
      }),
  };
};

export const getOptions = {
  filtering: false,
  sorting: true,
  headerStyle: {
    backgroundColor: ColorCodes.PRIMARY,
    color: ColorCodes.WHITE,
  },
  pageSize: 10,
  paging: false,
  addRowPosition: 'first',
};

export const getInfoboxTableOptions = {
  filtering: false,
  sorting: false,
  search: false,
  headerStyle: {
    backgroundColor: ColorCodes.PRIMARY,
    color: ColorCodes.WHITE,
  },
  pageSize: 5,
  pageSizeOptions: [],
  showFirstLastPageButtons: false,
  paging: true,
  showTitle: false,
  toolbar: false,
  emptyRowsWhenPaging: false,
}

export const getActions = [];

export const adInterimLookups = {
  true: "Yes",
  false: "No",
};

export const validTeamAttributes = [
  "id",
  "name",
  "lead",
  "reports",
  "supervisor",
];

export const validSupervisorAttributes = ["id", "name"];

export const validRoleAttributes = [
  "node_id",
  "capacity",
  "ad_interim",
  "role",
];
