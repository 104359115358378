import React from "react";
import _ from "lodash";

export const getNodeColumns = (nodes) => {
  const columns = [
    {
      title: "ID",
      field: "id",
      editable: "onAdd",
      sortable: true,
      validate: (rowData) => (rowData.id === "" ? "Id cannot be empty" : ""),
    },
    {
      title: "Name",
      field: "name",
      validate: (rowData) =>
        rowData.name === "" ? "Name cannot be empty" : "",
      sortable: true,
      customFilterAndSearch: (value, rowData) => {
        const filteredNodes = searchNodesByKeyword(nodes, value);
        if (filteredNodes && filteredNodes.length) {
          const nodeExists = _.filter(
            filteredNodes,
            (item) => item.id === rowData.id
          );
          if (nodeExists && nodeExists.length) {
            return true;
          } else {
            return false;
          }
        }
        return false; // customize here according your search algorithm.
      },
    },
    {
      title: "Type",
      field: "type",
      validate: (rowData) =>
        rowData.type === "" ? "Type cannot be empty" : "",
      sortable: true,
      lookup: nodeTypeLookups,
    },
    // {
    //   title: "Value",
    //   field: "value",
    //   validate: (rowData) =>
    //     rowData.value === "" ? "Value cannot be empty" : "",
    //   sortable: true,
    // },
    // {
    //   title: "Order",
    //   field: "order",
    //   sortable: true,
    // },
    {
      title: "Description",
      field: "description",
      sortable: true,
    },
  ];

  return columns;
};

export const searchNodesByKeyword = (nodes, searchTerm) => {
  const filteredNodes = nodes
    .filter((item) => {
      return iterateObject(item, searchTerm);
    })
    .filter((item) => item != null);

  function iterateObject(obj, searchTerm) {
    let isMatchFound = false;
    for (let prop in obj) {
      if (prop === "children") {
        const filteredChildNodes = obj[prop].filter((item) => {
          return iterateObject(item, searchTerm);
        });
        if (filteredChildNodes && filteredChildNodes.length) {
          return filteredChildNodes;
        }
      } else {
        if (obj[prop] && searchTerm) {
          let propValue = obj[prop].toString().toLowerCase();
          if (propValue && propValue.includes(searchTerm.toLowerCase())) {
            isMatchFound = true;
          }
        }
      }
    }
    if (isMatchFound) {
      return obj;
    } else {
      return null;
    }
  }

  return filteredNodes;
};

export const formatNodeData = (data) => {
  const clonedData = _.map(data, (item) => {
    item = _.pick(item, validNodeAttributes);
    if (item.level) {
      delete item["level"];
    }
    if (item.groupId) {
      delete item["groupId"];
    }
    if (item.index) {
      delete item["index"];
    }
    if (item.children) {
      item.children = _.map(item.children, (subItem) => {
        subItem = _.pick(subItem, validNodeAttributes);
        if (subItem.level) {
          delete subItem["level"];
        }
        if (subItem.groupId) {
          delete subItem["groupId"];
        }
        if (item.index) {
          delete item["index"];
        }
        if (subItem.children) {
          subItem.children = _.map(subItem.children, (garndSubItem) => {
            return _.pick(garndSubItem, validNodeAttributes);
          });
        }
        return subItem;
      });
    }

    return item;
  });
  return clonedData;
};

export const getConnectionsLookup = (nodes, parentRecord) => {
  let lookups = {};
  let items = [];
  // Looking into parent nodes
  nodes &&
    nodes.forEach((item, index) => {
      if (
        item.id !== parentRecord.id
        // parentRecord.connections &&
        // !_.includes(parentRecord.connections, item.id)
      )
        // lookups = {
        //   ...lookups,
        //   [item.id]: item.id,
        // };
        items.push({
          id: item.id,
          name: item.name,
        });
    });

  // Looking into child nodes
  nodes &&
    nodes.forEach((item, index) => {
      item.children &&
        item.children.forEach((childItem) => {
          if (childItem.id !== parentRecord.id)
            // lookups = {
            //   ...lookups,
            //   [childItem.id]: childItem.id,
            // };
            items.push({
              id: childItem.id,
              name: childItem.name,
            });
        });
    });

  // Looking into grand child nodes
  nodes &&
    nodes.forEach((item, index) => {
      item.children &&
        item.children.forEach((childItem) => {
          childItem.children &&
            childItem.children.forEach((grandChildItem) => {
              if (grandChildItem.id !== parentRecord.id)
                // lookups = {
                //   ...lookups,
                //   [grandChildItem.id]: grandChildItem.id,
                // };
                items.push({
                  id: grandChildItem.id,
                  name: grandChildItem.name,
                });
            });
        });
    });

  items = _.orderBy(items, ["name"], ["asc"]);
  items.forEach((item) => {
    lookups = {
      ...lookups,
      [item.id]: item.name,
    };
  });
  return lookups;
};

export const getNodeIdLookup = (nodes) => {
  let lookups = {};

  nodes = _.orderBy(nodes, ["name"], ["asc"]);
  // Looking into parent nodes
  nodes &&
    nodes.forEach((item, index) => {
      lookups = {
        ...lookups,
        [item.id]: item.name,
      };
    });

  // // Looking into child nodes
  // nodes &&
  //   nodes.forEach((item, index) => {
  //     item.children &&
  //       item.children.forEach((childItem) => {
  //         lookups = {
  //           ...lookups,
  //           [childItem.id]: childItem.name,
  //         };
  //       });
  //   });

  // // Looking into grand child nodes
  // nodes &&
  //   nodes.forEach((item, index) => {
  //     item.children &&
  //       item.children.forEach((childItem) => {
  //         childItem.children &&
  //           childItem.children.forEach((grandChildItem) => {
  //             lookups = {
  //               ...lookups,
  //               [grandChildItem.id]: grandChildItem.name,
  //             };
  //           });
  //       });
  //   });

  return lookups;
};

export const getNodeIdLookupV2 = (nodes) => {
  let lookups = {};
  let items = [];

  // Looking into parent nodes
  nodes &&
    nodes.forEach((item, index) => {
      // lookups = {
      //   ...lookups,
      //   [item.id]: item.name,
      // };
      items.push({
        id: item.id,
        name: item.name,
      });

      if (item.children) {
        item.children.forEach((childItem) => {
          // lookups = {
          //   ...lookups,
          //   [childItem.id]: childItem.name,
          // };

          items.push({
            id: childItem.id,
            name: childItem.name,
          });
          if (childItem.children) {
            childItem.children.forEach((grandChildItem) => {
              // lookups = {
              //   ...lookups,
              //   [grandChildItem.id]: grandChildItem.name,
              // };

              items.push({
                id: grandChildItem.id,
                name: grandChildItem.name,
              });

              if (grandChildItem.children) {
                grandChildItem.children.forEach((greatGrandChildItem) => {
                  // lookups = {
                  //   ...lookups,
                  //   [grandChildItem.id]: grandChildItem.name,
                  // };

                  items.push({
                    id: greatGrandChildItem.id,
                    name: greatGrandChildItem.name,
                  });
                });
              }
            });
          }
        });
      }

    });

  items = _.orderBy(items, ["name"], ["asc"]);
  items.forEach((item) => {
    lookups = {
      ...lookups,
      [item.id]: item.name,
    };
  });
  return lookups;
};

// export const getNodeIdLookupV2 = (nodes) => {
//   let lookups = {};
//   let items = [];

//   // Looking into parent nodes
//   nodes &&
//     nodes.forEach((item, index) => {
//       // lookups = {
//       //   ...lookups,
//       //   [item.id]: item.name,
//       // };
//       items.push({
//         id: item.id,
//         name: item.name,
//       });
//     });

//   // Looking into child nodes
//   nodes &&
//     nodes.forEach((item, index) => {
//       if (item.children) {
//         item.children.forEach((childItem) => {
//           // lookups = {
//           //   ...lookups,
//           //   [childItem.id]: childItem.name,
//           // };

//           items.push({
//             id: childItem.id,
//             name: childItem.name,
//           });
//         });
//       }
//     });

//   // Looking into grand child nodes
//   nodes &&
//     nodes.forEach((item, index) => {
//       item.children &&
//         item.children.forEach((childItem) => {
//           if (childItem.children) {
//             childItem.children.forEach((grandChildItem) => {
//               // lookups = {
//               //   ...lookups,
//               //   [grandChildItem.id]: grandChildItem.name,
//               // };

//               items.push({
//                 id: grandChildItem.id,
//                 name: grandChildItem.name,
//               });
//             });
//           }
//         });
//     });

//   items = _.orderBy(items, ["name"], ["asc"]);
//   items.forEach((item) => {
//     lookups = {
//       ...lookups,
//       [item.id]: item.name,
//     };
//   });
//   return lookups;
// };

export const nodeTypeLookups = {
  circle: "Center of Competence",
  "inner-circle": "Competence Team",
  oval: "Cross Competence Team",
};

// export const nodeTypeLookups = {
//   circle: "Center of Competence",
//   oval: "Cross-Competence Team",
//   "inner-circle-1": "Competence Team",
//   "inner-circle-2": "Expert Team ",
//   "inner-circle-3": "Expert",
// };

export const validNodeAttributes = [
  "id",
  "name",
  "type",
  "nodeIndex",
  "value",
  "children",
  "connections",
  "team",
  "order",
  "description",
  "x",
  "y"
];
