import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import Swal from "sweetalert2";
import _ from "lodash";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {formatRoleData} from "../../helpers/DataTable";
import {formatLinkData} from "../../helpers/LinkDataTable";
import {
  formatNodeData,
  searchNodesByKeyword,
} from "../../helpers/NodeDataTable";
import {formatPeopleData, getOrderedPeopleData} from "../../helpers/PeopleDataTable";
import {MenuItemsEnum} from "../../helpers/Enums";
import NodesDataService from "../../services/firebase/NodesDataService";
import LinksDataService from "../../services/firebase/LinksDataService";
import PeopleDataService from "../../services/firebase/PeopleDataService";
import {
  NodesDatatableComponent,
  LinksDatatableComponent,
  PeopleDatatableComponent,
  FaqDatatableComponent,
} from ".";
import {formatFaqData} from "helpers/FaqDataTable";
import FaqDataService from "services/firebase/FaqDataService";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    marginBottom: "0px",
    marginTop: "0px",
  },
  header: {
    alignItems: "end",
    marginTop: "24px",
    paddingLeft: "48px",
    paddingRight: "48px",
  },
  buttonContainer: {
    paddingTop: "16px",
  },
  button: {
    float: "right",
  },
  discardButton: {
    float: "right",
    marginLeft: "10px",
    backgroundColor: theme.palette.error.main,
  },
}));

function TableContainer(props) {
  const {handleMenuChange, activeTab} = props;
  // const srcNodes = JSON.parse(
  //   JSON.stringify((SourceJson && SourceJson.nodes) || [])
  // );
  // const srcLinks = JSON.parse(
  //   JSON.stringify((SourceJson && SourceJson.links) || [])
  // );

  const classes = useStyles();
  const [state, setState] = React.useState({
    value: 0,
    links: [],
    nodes: [],
    allNodes: [],
    people: [],
    allPeople: [],
    faq: [],
    isLoading: false,
    isLinkChanged: false,
    isNodeChanged: false,
    isPeopleChanged: false,
    isFaqChanged: false,
    rowsToBeDeleted: [],
    table: null,
    searchTerm: null,
    filteredNodes: [],
  });

  const {
    value,
    links,
    nodes,
    people,
    allPeople,
    allNodes,
    faq,
    isLoading,
    isLinkChanged,
    isNodeChanged,
    isPeopleChanged,
    isFaqChanged,
    rowsToBeDeleted,
    table,
    filteredNodes,
    searchTerm,
  } = state;

  useEffect(() => {
    let mounted = true;
    let nodes = [];
    let links = [];
    let people = [];
    let allPeople = [];
    let allNodes = [];
    let faq = [];
    // Getting nodes from firebase
    NodesDataService.getAll().then((items) => {
      if (mounted) {
        nodes = items;
        allNodes = items;
        setState({
          ...state,
          nodes: nodes,
          links: links,
          people: people,
          allPeople: allPeople,
          allNodes: allNodes,
        });
      }
    });

    // Getting links from firebase
    LinksDataService.getAll().then((items) => {
      if (mounted) {
        links = items;
        setState({
          ...state,
          nodes: nodes,
          links: links,
          people: people,
          allPeople: allPeople,
          allNodes: allNodes,
        });
      }
    });

    // Getting people from firebase
    PeopleDataService.getAll().then((items) => {
      if (mounted) {
        people = items;
        setState({
          ...state,
          nodes: nodes,
          links: links,
          people: people,
          allPeople: people,
          allNodes: allNodes,
        });
      }
    });

    // Getting faq from firebase
    FaqDataService.getAll().then((items) => {
      if (mounted) {
        faq = items;
        setState({
          ...state,
          nodes: nodes,
          links: links,
          people: people,
          faq: faq,
          allPeople: allPeople,
          allNodes: allNodes,
        });
      }
    });
    return () => (mounted = false);
  }, []);

  const handleLinksChange = (value, id, operation) => {
    if (isLoading) return;
    const links = formatLinkData(value);
    setState({...state, isLoading: true});
    let isLinkChanged = true;
    const rowsToDelete =
      operation === "delete"
        ? rowsToBeDeleted && rowsToBeDeleted.length
          ? [...rowsToBeDeleted, id]
          : [id]
        : rowsToBeDeleted;

    LinksDataService.create(links)
      .then((res) => {
        isLinkChanged = false;
        setState({
          ...state,
          isLoading: false,
          isLinkChanged: isLinkChanged,
          links: links,
          table: "links",
        });
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "success",
          title: "Lines data has been saved!",
        });

        // Deleting rows from firestore as well
        if (rowsToDelete && rowsToDelete.length) {
          LinksDataService.delete(rowsToBeDeleted);
          // Remove the items with deleted ids
          _.forEach(rowsToDelete, (rowId) => {
            _.remove(
              links,
              (item) => item.source + "-" + item.target === rowId
            );
          });
          setState({...state, rowsToBeDeleted: [], links: links});
        }
      })
      .catch((err) => {
        if (err) {
          setState({...state, isLoading: false});
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: JSON.stringify(err),
          });

          throw err;
        }
      });
  };

  const handleNodesChange = (value, id, operation) => {
    if (isLoading) return;
    const nodes = formatNodeData(value);
    setState({...state, isLoading: true});
    let isNodeChanged = true;
    const rowsToDelete =
      operation === "delete"
        ? rowsToBeDeleted && rowsToBeDeleted.length
          ? [...rowsToBeDeleted, id]
          : [id]
        : rowsToBeDeleted;
    NodesDataService.create(nodes)
      .then((res) => {
        isNodeChanged = false;
        setState({
          ...state,
          isLoading: false,
          isNodeChanged: isNodeChanged,
          nodes: nodes,
          table: "nodes",
        });
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "success",
          title: "Circles data has been saved!",
        });

        // Deleting rows from firestore as well
        if (rowsToDelete && rowsToDelete.length) {
          NodesDataService.delete(rowsToDelete);
          // Remove the items with deleted ids
          _.forEach(rowsToDelete, (rowId) => {
            _.remove(nodes, (item) => item.id === rowId);
          });
          setState({...state, rowsToBeDeleted: [], nodes: nodes});

          // If the node is getting deleted then we need to delete its corresponding links as well
          const linkIdsToBeDeleted = [];
          rowsToDelete &&
            rowsToDelete.forEach((nodeId) => {
              const linksForNodeId = _.filter(
                links,
                (item) =>
                  item.source === nodeId ||
                  item.target === nodeId ||
                  item.start_id === nodeId ||
                  item.end_id === nodeId
              );
              linksForNodeId &&
                _.forEach(linksForNodeId, (link) => {
                  linkIdsToBeDeleted.push(link.source + "-" + link.target);
                });
            });
          console.log("linkIdsToBeDeleted", linkIdsToBeDeleted);
          if (linkIdsToBeDeleted && linkIdsToBeDeleted.length) {
            LinksDataService.delete(linkIdsToBeDeleted);
            // Remove the items with deleted ids
            _.forEach(linkIdsToBeDeleted, (rowId) => {
              _.remove(
                links,
                (item) => item.source + "-" + item.target === rowId
              );
            });
            setState({...state, rowsToBeDeleted: [], links: links});
          }
        }
      })
      .catch((err) => {
        if (err) {
          setState({...state, isLoading: false});
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: JSON.stringify(err),
          });

          throw err;
        }
      });
  };

  const handlePeopleChange = (value, id, operation) => {
    if (isLoading) return;
    const people = formatPeopleData(value);
    setState({...state, isLoading: true});
    let isPeopleChanged = true;
    const rowsToDelete =
      operation === "delete"
        ? rowsToBeDeleted && rowsToBeDeleted.length
          ? [...rowsToBeDeleted, id]
          : [id]
        : rowsToBeDeleted;

    PeopleDataService.create(people)
      .then((res) => {
        isPeopleChanged = false;
        setState({
          ...state,
          isLoading: false,
          isPeopleChanged: isPeopleChanged,
          people: people,
          table: "people",
        });
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "success",
          title: "People data has been saved!",
        });

        // Deleting rows from firestore as well
        if (rowsToDelete && rowsToDelete.length) {
          PeopleDataService.delete(rowsToDelete);
          // Remove the items with deleted ids
          _.forEach(rowsToDelete, (rowId) => {
            _.remove(people, (item) => item.id === rowId);
          });
          setState({...state, rowsToBeDeleted: [], people: people});
        }
      })
      .catch((err) => {
        if (err) {
          setState({...state, isLoading: false});
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: JSON.stringify(err),
          });

          throw err;
        }
      });
  };

  const handleFaqChange = (value, id, operation) => {
    if (isLoading) return;
    const faq = formatFaqData(value);
    setState({...state, isLoading: true});
    let isFaqChanged = true;
    const rowsToDelete =
      operation === "delete"
        ? rowsToBeDeleted && rowsToBeDeleted.length
          ? [...rowsToBeDeleted, id]
          : [id]
        : rowsToBeDeleted;

    FaqDataService.create(faq)
      .then((res) => {
        isFaqChanged = false;
        setState({
          ...state,
          isLoading: false,
          isFaqChanged: isFaqChanged,
          faq: faq,
          table: "faq",
        });
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "success",
          title: "Help data has been saved!",
        });

        // Deleting rows from firestore as well
        if (rowsToDelete && rowsToDelete.length) {
          FaqDataService.delete(rowsToDelete);
          // Remove the items with deleted ids
          _.forEach(rowsToDelete, (rowId) => {
            _.remove(faq, (item) => item.id === rowId);
          });
          setState({...state, rowsToBeDeleted: [], faq: faq});
        }
      })
      .catch((err) => {
        if (err) {
          setState({...state, isLoading: false});
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: JSON.stringify(err),
          });

          throw err;
        }
      });
  };

  const handlePeopleChangeForNodes = (updatedRoles, currentNode, operation) => {
    console.log("handlePeopleChangeForNodes => updatedRoles =>", updatedRoles);
    console.log("handlePeopleChangeForNodes => currentNode =>", currentNode);
    console.log("handlePeopleChangeForNodes => operation =>", operation);

    let peopleToUpdate = [];
    people &&
      people.forEach((person) => {
        const rolesWithoutCurrentNode =
          person &&
          person.roles &&
          person.roles
            .filter((r) => r.node_id !== currentNode.id)
            .filter((item) => item !== null && item !== undefined);

        const rolesWithCurrentNode =
          person &&
          person.roles &&
          person.roles
            .filter((r) => r.node_id === currentNode.id)
            .filter((item) => item !== null && item !== undefined);

        if (rolesWithoutCurrentNode && rolesWithoutCurrentNode.length) {
          person.roles = rolesWithoutCurrentNode;
        } else {
          person.roles = [];
        }

        // Find latest role for the person
        if (updatedRoles && updatedRoles.length) {
          const newRoles = updatedRoles.filter((item) => {
            if (item.person_id === person.id) {
              item.node_id = currentNode.id;
              return item;
            }
          });
          if (newRoles && newRoles.length) {
            person.roles = [...person.roles, ...newRoles];

            // format the roles before updating them
            person.roles = formatRoleData(person.roles);
            peopleToUpdate.push(person);
          } else if (rolesWithCurrentNode && rolesWithCurrentNode.length) {
            peopleToUpdate.push(person);
          }
        }
        return person;
      });
    console.log("peopleList", peopleToUpdate);
    if (peopleToUpdate && peopleToUpdate.length) {
      const people = formatPeopleData(peopleToUpdate);
      setState({...state, isLoading: true});
      PeopleDataService.update(people)
        .then((res) => {
          setState({
            ...state,
            isLoading: false,
          });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });

          Toast.fire({
            icon: "success",
            title: "People changes are saved successfully!",
          });
        })
        .catch((err) => {
          if (err) {
            setState({...state, isLoading: false});
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: JSON.stringify(err),
            });

            throw err;
          }
        });
    } else {
      if (people && people.length) {
        const peopleToUpdate = formatPeopleData(people);
        setState({...state, isLoading: true});
        PeopleDataService.update(peopleToUpdate)
          .then((res) => {
            setState({
              ...state,
              isLoading: false,
            });
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: "success",
              title: "People changes are saved successfully!",
            });
          })
          .catch((err) => {
            if (err) {
              setState({...state, isLoading: false});
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: JSON.stringify(err),
              });

              throw err;
            }
          });
      }
    }
  };

  const handleTeamChangeForNodes = (updatedTeams, currentNode, operation) => {
    console.log("handleTeamChangeForNodes => updatedTeams =>", updatedTeams);
    console.log("handleTeamChangeForNodes => currentNode =>", currentNode);
    console.log("handleTeamChangeForNodes => operation =>", operation);
    
    let peopleToUpdate = [];
    people &&
      people.forEach((person) => {
        const teamsWithoutCurrentNode =
          person &&
          person.teams &&
          person.teams
            .filter((r) => r.node_id !== currentNode.id)
            .filter((item) => item !== null && item !== undefined);

        const teamsWithCurrentNode =
          person &&
          person.teams &&
          person.teams
            .filter((r) => r.node_id === currentNode.id)
            .filter((item) => item !== null && item !== undefined);

        if (teamsWithoutCurrentNode && teamsWithoutCurrentNode.length) {
          person.teams = teamsWithoutCurrentNode;
        } else {
          person.teams = [];
        }

        // Find latest team for the person
        if (updatedTeams && updatedTeams.length) {
          const newTeams = updatedTeams.filter((item) => {
            if (item.person_id === person.id) {
              item.node_id = currentNode.id;
              return item;
            }
          });
          if (newTeams && newTeams.length) {
            person.teams = [...person.teams, ...newTeams];

            // format the temas before updating them
            person.teams = formatRoleData(person.teams);
            peopleToUpdate.push(person);
          } else if (teamsWithCurrentNode && teamsWithCurrentNode.length) {
            peopleToUpdate.push(person);
          }
        }
        return person;
      });
    console.log("peopleList", peopleToUpdate);
    if (peopleToUpdate && peopleToUpdate.length) {
      const people = formatPeopleData(peopleToUpdate);
      setState({...state, isLoading: true});
      PeopleDataService.update(people)
        .then((res) => {
          setState({
            ...state,
            isLoading: false,
          });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });

          Toast.fire({
            icon: "success",
            title: "People changes are saved successfully!",
          });
        })
        .catch((err) => {
          if (err) {
            setState({...state, isLoading: false});
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: JSON.stringify(err),
            });

            throw err;
          }
        });
    } else {
      if (people && people.length) {
        const peopleToUpdate = formatPeopleData(people);
        setState({...state, isLoading: true});
        PeopleDataService.update(peopleToUpdate)
          .then((res) => {
            setState({
              ...state,
              isLoading: false,
            });
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: "success",
              title: "People changes are saved successfully!",
            });
          })
          .catch((err) => {
            if (err) {
              setState({...state, isLoading: false});
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: JSON.stringify(err),
              });

              throw err;
            }
          });
      }
    }
  };

  const saveJsonToFile = () => {
    if (isLoading) return;
    setState({...state, isLoading: true});

    // Complete JSON data to be output on JSON file
    const completeJson = {
      nodes: nodes,
      links: links,
    };

    Swal.fire({
      icon: "warning",
      title: "Do you want to save the changes?",
      text:
        "If you proceed to save the changes, the existing contents of the file will be overriden with the new changes.",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
      width: "750px",
      customClass: {
        confirmButtonClass: classes.button,
      },
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        await axios
          .post("http://localhost:8080/api/upload-json", {
            url: "../dg-react/src/resources/data/source-data.json",
            data: completeJson,
          })
          .then((res) => {
            setState({
              ...state,
              isLoading: false,
              isNodeChanged: false,
              isLinkChanged: false,
              isPeopleChanged: false,
            });
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: "success",
              title: "Json saved successfully!",
            });
            handleMenuChange(MenuItemsEnum.DATA_TABLE);
            // console.log("Done writing"); // Success
          })
          .catch((err) => {
            if (err) {
              setState({...state, isLoading: false});
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: JSON.stringify(err),
              });

              throw err;
            }
          });
      } else if (result.isDenied) {
        setState({...state, isLoading: false});
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "info",
          title: "Changes are not saved!",
        });
      } else {
        setState({...state, isLoading: false});
      }
    });
  };

  const saveJsonToFirebase = () => {
    if (isLoading) return;
    setState({...state, isLoading: true});
    let isNodeChanged = true;
    let isLinkChanged = true;
    let isPeopleChanged = true;
    Swal.fire({
      icon: "warning",
      title: "Do you want to save the changes?",
      text:
        "If you proceed to save the changes, the existing contents of the file will be overriden with the new changes.",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
      width: "750px",
      customClass: {
        confirmButtonClass: classes.button,
      },
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        NodesDataService.create(nodes)
          .then((res) => {
            isNodeChanged = false;
            setState({
              ...state,
              isLoading: false,
              isNodeChanged: isNodeChanged,
            });
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: "success",
              title: "Nodes Json saved successfully in firebase!",
            });

            // Deleting rows from firestore as well
            if (
              rowsToBeDeleted &&
              rowsToBeDeleted.length &&
              table === "nodes"
            ) {
              NodesDataService.delete(rowsToBeDeleted);
              setState({...state, rowsToBeDeleted: []});

              // If the node is getting deleted then we need to delete its corresponding links as well
              const linkIdsToBeDeleted = [];
              rowsToBeDeleted &&
                rowsToBeDeleted.forEach((nodeId) => {
                  const linksForNodeId = _.filter(
                    links,
                    (item) =>
                      item.source === nodeId ||
                      item.target === nodeId ||
                      item.start_id === nodeId ||
                      item.end_id === nodeId
                  );
                  linksForNodeId &&
                    _.forEach(linksForNodeId, (link) => {
                      linkIdsToBeDeleted.push(link.source + "-" + link.target);
                    });
                });
              console.log("linkIdsToBeDeleted", linkIdsToBeDeleted);
              if (linkIdsToBeDeleted && linkIdsToBeDeleted.length) {
                LinksDataService.delete(linkIdsToBeDeleted);
              }
            }
          })
          .catch((err) => {
            if (err) {
              setState({...state, isLoading: false});
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: JSON.stringify(err),
              });

              throw err;
            }
          });

        LinksDataService.create(links)
          .then((res) => {
            isLinkChanged = false;
            setState({
              ...state,
              isLoading: false,
              isLinkChanged: isLinkChanged,
            });
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: "success",
              title: "Links Json saved successfully in firebase!",
            });

            // Deleting rows from firestore as well
            if (
              rowsToBeDeleted &&
              rowsToBeDeleted.length &&
              table === "links"
            ) {
              LinksDataService.delete(rowsToBeDeleted);
              setState({...state, rowsToBeDeleted: []});
            }
          })
          .catch((err) => {
            if (err) {
              setState({...state, isLoading: false});
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: JSON.stringify(err),
              });

              throw err;
            }
          });

        PeopleDataService.create(people)
          .then((res) => {
            isPeopleChanged = false;
            setState({
              ...state,
              isLoading: false,
              isPeopleChanged: isPeopleChanged,
              isNodeChanged: isNodeChanged,
              isLinkChanged: isLinkChanged,
            });
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: "success",
              title: "People Json saved successfully in firebase!",
            });

            // Deleting rows from firestore as well
            if (
              rowsToBeDeleted &&
              rowsToBeDeleted.length &&
              table === "people"
            ) {
              PeopleDataService.delete(rowsToBeDeleted);
              setState({...state, rowsToBeDeleted: []});
            }
          })
          .catch((err) => {
            if (err) {
              setState({...state, isLoading: false});
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: JSON.stringify(err),
              });

              throw err;
            }
          });
      } else if (result.isDenied) {
        setState({...state, isLoading: false});
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "info",
          title: "Changes are not saved!",
        });
      } else {
        setState({...state, isLoading: false});
      }
    });
  };

  const discardChanges = () => {
    Swal.fire({
      icon: "warning",
      title: "Do you want to discard the changes?",
      text:
        "If you proceed to discard the changes, your changes will be lost and this action can't be undone.",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Discard`,
      denyButtonText: `Don't discard`,
      width: "750px",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setState({
          ...state,
          // links: srcLinks,
          // nodes: srcNodes,
          isLoading: false,
          isLinkChanged: false,
          isNodeChanged: false,
          isPeopleChanged: false,
        });
      }
    });
  };

  const handleNodeRefresh = () => {
    setState({...state, isLoading: true});
    setTimeout(() => {
      setState({
        ...state,
        // nodes: srcNodes,
        isLoading: false,
        isNodeChanged: false,
      });
    }, 1000);
  };

  const handleLinkRefresh = () => {
    setState({...state, isLoading: true});
    setTimeout(() => {
      setState({
        ...state,
        // links: srcLinks,
        isLoading: false,
        isLinkChanged: false,
      });
    }, 1000);
  };

  const handlePeopleRefresh = () => {
    setState({...state, isLoading: true});
    setTimeout(() => {
      setState({
        ...state,
        // links: srcLinks,
        isLoading: false,
        isPeopleChanged: false,
      });
    }, 1000);
  };

  const handleFaqRefresh = () => {
    setState({...state, isLoading: true});
    setTimeout(() => {
      setState({
        ...state,
        // links: srcLinks,
        isLoading: false,
        isFaqChanged: false,
      });
    }, 1000);
  };

  const handleNodeSearch = (searchTerm) => {
    const filteredNodes = searchNodesByKeyword(nodes, searchTerm);
    setState({
      ...state,
      filteredNodes: filteredNodes,
      searchTerm: searchTerm,
    });
  };

  return (
    <div className={classes.root}>
      <TabPanel value={activeTab} index={0}>
        <NodesDatatableComponent
          data={formatNodeData(searchTerm ? filteredNodes : nodes)}
          people={formatPeopleData(people)}
          allNodes={allNodes}
          isLoading={isLoading}
          handleNodesChange={handleNodesChange}
          handleNodeRefresh={handleNodeRefresh}
          handlePeopleChange={handlePeopleChangeForNodes}
          handleTeamChangeForNodes ={handleTeamChangeForNodes}
          handleNodeSearch={handleNodeSearch}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <LinksDatatableComponent
          data={formatLinkData(links)}
          nodes={formatNodeData(nodes)}
          isLoading={isLoading}
          handleLinksChange={handleLinksChange}
          handleLinkRefresh={handleLinkRefresh}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <PeopleDatatableComponent
          data={formatPeopleData(people)}
          nodes={formatNodeData(nodes)}
          isLoading={isLoading}
          handlePeopleChange={handlePeopleChange}
          handlePeopleRefresh={handlePeopleRefresh}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        <FaqDatatableComponent
          data={formatFaqData(faq)}
          isLoading={isLoading}
          handleFaqChange={handleFaqChange}
          handleFaqRefresh={handleFaqRefresh}
        />
      </TabPanel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  activeTab: state.navigations.activeSubItem || 0,
});

export default withRouter(connect(mapStateToProps)(TableContainer));
