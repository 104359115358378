export const navigations = [
  {
    name: "Homepage",
    path: "/homepage",
    icon: "dashboard",
  },
  {
    name: "CMS",
    path: "/data",
    icon: "dashboard",
  },
];

export const userNavigations = [
  {
    name: "Homepage",
    path: "/homepage",
    icon: "dashboard",
  },
  {
    name: "CMS",
    path: "/data",
    icon: "dashboard",
  },
];
