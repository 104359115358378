import React from "react";
import _ from "lodash";
import MaterialTable from "material-table";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  getEditableComponent,
  getOptions,
  useStyles,
} from "../../helpers/DataTable";
import { getNodeColumns } from "../../helpers/NodeDataTable";
import {
  getPeopleColumnsForNodeTable,
  getTeamColumnsForNodeTable,
  getPeopleRoleForNode,
  getPeopleTeamForNode,
} from "../../helpers/PeopleDataTable";
import { tableIcons } from "../../components/ReusableComponents/DatatableIconsComponent";
import { ChildTableComponent, ConnectionsTableComponent } from "../../components";

export default function NodesDatatableComponent(props) {
  const {
    data,
    allNodes,
    people,
    isLoading,
    handleNodesChange,
    handleNodeRefresh,
    handlePeopleChange,
    handleTeamChangeForNodes,
    handleNodeSearch,
  } = props;
  const classes = useStyles();
  const columns = getNodeColumns(data);

  const handleConnectionsChange = (updatedConnections, rowData) => {
    const nodes = [...data];
    rowData.connections = updatedConnections;
    const index = rowData.tableData.id;
    nodes[index] = rowData;
    handleNodesChange([...nodes]);
  };

  const handleChildComponentChange = (updatedChildren, rowData) => {
    const nodes = [...data];
    rowData.children = updatedChildren;
    const index = rowData.tableData.id;
    nodes[index] = rowData;
    handleNodesChange([...nodes]);
  };

  const handleGrandChildComponentChange = (
    updatedGrandChildren,
    parentRowData,
    grandParentRowData
  ) => {
    const nodes = [...data];
    const grandParentIndex = _.indexOf(nodes, grandParentRowData);

    if (grandParentIndex !== -1) {
      // Update grandchildren collection on children record
      parentRowData.children = updatedGrandChildren;
      // Children index in parent collection
      const index = parentRowData.tableData.id;
      grandParentRowData.children[index] = parentRowData;
      nodes[grandParentIndex] = grandParentRowData;
      handleNodesChange([...nodes]);
    }
  };

  const handleGreatGrandChildComponentChange = (updatedGrandChildren,
    parentRowData,
    grandParentRowData, greatGrandParentRecord) => {
    const nodes = [...data];
    const greatGrandParentIndex = _.indexOf(nodes, greatGrandParentRecord);

    if (greatGrandParentIndex !== -1) {

      // Update grandchildren collection on children record
      parentRowData.children = updatedGrandChildren;
      // Children index in parent collection
      const index = parentRowData.tableData.id;
      grandParentRowData.children[index] = parentRowData;

      // Index of grandParentRowData
      const grandParentIndex = _.indexOf(greatGrandParentRecord.children || [], grandParentRowData);

      if (grandParentIndex !== -1) {
        greatGrandParentRecord.children[grandParentIndex] = grandParentRowData;
      }

      nodes[greatGrandParentIndex] = greatGrandParentRecord;
      handleNodesChange([...nodes]);
    }
  }

  return (
    <main className={classes.content}>
      <Container className={classes.container}>
        {/* JSON viewer conatainer*/}
        <Grid container className={classes.viewerSettings}>
          <Grid item xs={12} md={8} lg={12}>
            <div style={{ maxWidth: "100%" }}>
              <MaterialTable
                icons={tableIcons}
                isLoading={isLoading}
                detailPanel={(rowData) => {
                  const childrenTableData = rowData.children || [];
                  const peopleData = getPeopleRoleForNode(people, rowData.id);
                  const teamData = getPeopleTeamForNode(
                    people,
                    rowData.id
                  );
                  return (
                    <div>
                      {/** People */}
                      <ChildTableComponent
                        columns={getPeopleColumnsForNodeTable(people)}
                        data={peopleData}
                        parentRecord={rowData}
                        tableHeader={`People for ${rowData.name}`}
                        tableIcons={tableIcons}
                        handleChange={handlePeopleChange}
                      />

                      {/** Teams */}
                      <ChildTableComponent
                        columns={getTeamColumnsForNodeTable(
                          people
                        )}
                        data={teamData}
                        parentRecord={rowData}
                        tableHeader={`Team for ${rowData.name}`}
                        tableIcons={tableIcons}
                        handleChange={handleTeamChangeForNodes}
                      />

                      {/** Connections*/}
                      {rowData.connections && (
                        <ConnectionsTableComponent
                          data={data}
                          allNodes={allNodes}
                          rowData={rowData}
                          handleConnectionsChange={handleConnectionsChange}
                        />
                      )}

                      {/** Children */}

                      <ChildTableComponent
                        columns={getNodeColumns(rowData.children || [])}
                        data={childrenTableData}
                        parentRecord={rowData}
                        tableHeader={`Sub-Circles for ${rowData.name}`}
                        tableIcons={tableIcons}
                        detailPanel={(childRowData) => {
                          {
                            /** Grand Children */
                          }
                          const grandChildrenTableData =
                            childRowData.children || [];

                          const peopleData = getPeopleRoleForNode(
                            people,
                            childRowData.id
                          );

                          const teamData = getPeopleTeamForNode(
                            people,
                            childRowData.id
                          );
                          return (
                            <div>
                              {/** People */}
                              <ChildTableComponent
                                columns={getPeopleColumnsForNodeTable(people)}
                                data={peopleData}
                                parentRecord={childRowData}
                                tableHeader={`People for ${childRowData.name}`}
                                tableIcons={tableIcons}
                                handleChange={handlePeopleChange}
                              />
                              {/** Teams */}
                              <ChildTableComponent
                                columns={getTeamColumnsForNodeTable(
                                  people
                                )}
                                data={teamData}
                                parentRecord={childRowData}
                                tableHeader={`Team for ${childRowData.name}`}
                                tableIcons={tableIcons}
                                handleChange={handleTeamChangeForNodes}
                              />
                              <ChildTableComponent
                                columns={getNodeColumns(
                                  childRowData.children || []
                                )}
                                data={grandChildrenTableData}
                                parentRecord={childRowData}
                                grandParentRecord={rowData}
                                tableHeader={`Sub-Circles for ${childRowData.name}`}
                                tableIcons={tableIcons}
                                detailPanel={(grandChildRowData) => {
                                  {
                                    /** Great Grand Children */
                                  }

                                  const peopleData = getPeopleRoleForNode(
                                    people,
                                    grandChildRowData.id
                                  );

                                  const teamData = getPeopleTeamForNode(
                                    people,
                                    grandChildRowData.id
                                  );
                                  return (
                                    <div>
                                      {/** People */}
                                      <ChildTableComponent
                                        columns={getPeopleColumnsForNodeTable(
                                          people
                                        )}
                                        data={peopleData}
                                        parentRecord={grandChildRowData}
                                        tableHeader={`People for ${grandChildRowData.name}`}
                                        tableIcons={tableIcons}
                                        handleChange={handlePeopleChange}
                                      />

                                      {/** Teams */}
                                      <ChildTableComponent
                                        columns={getTeamColumnsForNodeTable(
                                          people
                                        )}
                                        data={teamData}
                                        parentRecord={grandChildRowData}
                                        tableHeader={`Team for ${grandChildRowData.name}`}
                                        tableIcons={tableIcons}
                                        handleChange={handleTeamChangeForNodes}
                                      />
                                      {/** Sub-circles */}
                                      <ChildTableComponent
                                        columns={getNodeColumns(
                                          grandChildRowData.children || []
                                        )}
                                        data={grandChildRowData.children || []}
                                        parentRecord={grandChildRowData}
                                        grandParentRecord={childRowData}
                                        greatGrandParentRecord={rowData}
                                        tableHeader={`Sub-Circles for ${grandChildRowData.name}`}
                                        tableIcons={tableIcons}
                                        detailPanel={(greatGrandChildRowData) => {
                                          {
                                            /** Great Great Grand Children */
                                          }

                                          const peopleData = getPeopleRoleForNode(
                                            people,
                                            greatGrandChildRowData.id
                                          );

                                          const teamData = getPeopleTeamForNode(
                                            people,
                                            greatGrandChildRowData.id
                                          );
                                          return (
                                            <div>
                                              {/** People */}
                                              <ChildTableComponent
                                                columns={getPeopleColumnsForNodeTable(
                                                  people
                                                )}
                                                data={peopleData}
                                                parentRecord={greatGrandChildRowData}
                                                tableHeader={`People for ${greatGrandChildRowData.name}`}
                                                tableIcons={tableIcons}
                                                handleChange={
                                                  handlePeopleChange
                                                }
                                              />

                                              {/** Teams */}
                                              <ChildTableComponent
                                                columns={getTeamColumnsForNodeTable(
                                                  people
                                                )}
                                                data={teamData}
                                                parentRecord={greatGrandChildRowData}
                                                tableHeader={`Team for ${greatGrandChildRowData.name}`}
                                                tableIcons={tableIcons}
                                                handleChange={
                                                  handleTeamChangeForNodes
                                                }
                                              />
                                            </div>
                                          );
                                        }}
                                        handleChange={
                                          handleGreatGrandChildComponentChange
                                        }
                                      />
                                    </div>
                                  );
                                }}
                                handleChange={handleGrandChildComponentChange}
                              />
                            </div>
                          );
                        }}
                        handleChange={handleChildComponentChange}
                      />
                    </div>
                  );
                }}
                columns={columns}
                data={data}
                title={
                  <div className={classes.iconContainer}>
                    <SettingsIcon />
                    <h2 className={classes.headerText}>Circles </h2>
                  </div>
                }
                options={{ ...getOptions, search: true }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                editable={getEditableComponent(data, handleNodesChange)}
                actions={[
                  {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    onClick: () => handleNodeRefresh(),
                  },
                ]}
                onSearchChange={(searchTerm) => {
                  console.log("search");
                  console.log(searchTerm);
                  handleNodeSearch(searchTerm);
                  // console.log(tableRef.current.DataManager.filteredData.length);
                }}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Search nodes",
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
