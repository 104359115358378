import Swal from "sweetalert2";
import FirebaseAuthService from "../../services/firebase/FirebaseAuthService";
import UserDataService from "../../services/firebase/UserDataService";
import { setUserData } from "./UserActions";
import history from "history.js";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_LOADING = "FORGOT_PASSWORD_LOADING";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_LOADING = "SIGNUP_LOADING";

export function setLoading(action) {
  return (dispatch) => {
    dispatch({
      type: action,
    });
  };
}

export function firebaseSendPasswordResetEmail({ email }) {
  return (dispatch) => {
    FirebaseAuthService.sendPasswordResetEmail(email)
      .then((user) => {
        history.push({
          pathname: "/signin",
        });

        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "success",
          title:
            "An email is sent to your registered email address with password reset instructions.",
        });
        return dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
        });
      })
      .catch((error) => {
        return dispatch({
          type: FORGOT_PASSWORD_ERROR,
          payload: error,
        });
      });
  };
}

export function firebaseLoginEmailPassword({ email, password }) {
  return (dispatch) => {
    FirebaseAuthService.signInWithEmailAndPassword(email, password)
      .then((data) => {
        const user = data && data.user;
        if (user) {
          // Getting people from firebase
          UserDataService.getUserRole(user.uid).then((data) => {
            const userObj = {
              ...user,
              role: data && data.role ? data.role : "admin"
            }
            console.log("userdataservice => data", data);
            dispatch(
              setUserData({
                ...userObj
              })
            );

            history.push({
              pathname: "/homepage",
            });
            
            return dispatch({
              type: LOGIN_SUCCESS,
              payload: userObj,
            });
          }).catch(error => {
            return dispatch({
              type: LOGIN_ERROR,
              payload: "Login Failed",
            });
          });

        } else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: "Login Failed",
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: error,
        });
      });
  };
}

export function firebaseSignUpWithEmailAndPassword({
  displayName,
  email,
  password,
}) {
  return (dispatch) => {
    FirebaseAuthService.signUpWithEmailAndPassword(email, password)
      .then(async (data) => {
        const user = data && data.user;
        if (user) {
          await user
            .updateProfile({
              displayName: displayName,
            })
            .then((result) => {
              dispatch(
                setUserData({
                  ...user,
                })
              );

              history.push({
                pathname: "/signin",
              });

              const Toast = Swal.mixin({
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });

              Toast.fire({
                icon: "success",
                title: "User successfully created.",
              });

              return dispatch({
                type: SIGNUP_SUCCESS,
              });
            })
            .catch((err) => {
              return dispatch({
                type: SIGNUP_ERROR,
                payload: err,
              });
            });

          // This code block stores the information in the users collection
          // await FirebaseAuthService.createUserProfileDocument({
          //   ...user,
          //   displayName: displayName,
          // });
        } else {
          return dispatch({
            type: SIGNUP_ERROR,
            payload: "Signup Failed",
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: SIGNUP_ERROR,
          payload: error,
        });
      });
  };
}
