import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";

class NodesDataService {
  db;

  constructor() {
    this.db = firebase.firestore();
  }

  getAll = () => {
    return this.db
      .collection("nodes")
      .get()
      .then((docList) => {
        let items = [];
        docList.docs.forEach((doc) => {
          // console.log(doc.data());
          items.push(doc.data());
        });
        return items;
      })
      .catch((error) => error);
  };

  create = async (nodes) => {
    const batch = this.db.batch();
    nodes.map((item) => {
      const collectionRef = this.db.collection("nodes").doc(item.id);
      batch.set(collectionRef, item);
    });

    await batch
      .commit()
      .then()
      .catch((error) => error);
  };

  delete = (nodeIds) => {
    nodeIds.map((id) => {
      return this.db
        .collection("nodes")
        .doc(id)
        .delete()
        .then()
        .catch((error) => error);
    });
  };

  update = async (node) => {
    let id = node.id;
    const db = firebase.firestore();
    this.db
      .collection("nodes")
      .doc(id)
      .update({
        x: node.x,
        y: node.y,
      })
      .then()
      .catch((error) => error);
  };
}

export default new NodesDataService();
