import {authRoles} from "auth/authRoles";
import UserProfilePage from "./UserProfilePage";

const userRoutes = [
  {
    path: "/user-profile",
    component: UserProfilePage,
    auth: authRoles.admin,
  },
];

export default userRoutes;
