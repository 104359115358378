import React from "react";
import _ from "lodash";
import {getConnectionsLookup} from "../helpers/NodeDataTable";
import {FieldTypeEnum} from "../helpers/Enums";
import {tableIcons} from "./ReusableComponents/DatatableIconsComponent";
import {ChildTableComponent} from ".";

export default function ConnectionsTableComponent(props) {
  const {allNodes, data, rowData, handleConnectionsChange} = props;

  // Construct connections object array
  const constructConnectionsArray = (rowData) => {
    const connections = rowData.connections || [];
    const connectionsDataArr = connections
      .map((item) => {
        // const filtered = _.find(allNodes, (nItem) => nItem.id === item);
        // if (filtered && filtered.id) {
        //   return {nodeId: rowData.id, connectionName: item};
        // } else {
        //   return null;
        // }
        return {nodeId: rowData.id, connectionName: item};
      })
      .filter((ele) => ele != null && ele.connectionName !== null);
    return connectionsDataArr;
  };

  const getConnectionColumns = (rowData) => {
    const connectionColumns = [
      {
        title: "Node",
        field: "nodeId",
        editable: "never",
        sortable: true,
        initialEditValue: rowData.id,
        validate: (rowData) =>
          rowData.nodeId === "" ? "Node ID cannot be empty" : "",
      },
      {
        title: "Connection Name",
        field: "connectionName",
        validate: (rowData) =>
          rowData.connectionName === ""
            ? "Connection Name cannot be empty"
            : "",
        sortable: true,
        editPlaceholder: "Select a value",
        lookup: getConnectionsLookup(allNodes, rowData),
      },
    ];
    return connectionColumns;
  };

  const handleChange = (connections) => {
    const connectionsClone = _.map(connections, (item) => item.connectionName);
    handleConnectionsChange(connectionsClone, rowData);
  };

  return (
    <ChildTableComponent
      columns={getConnectionColumns(rowData)}
      data={constructConnectionsArray(rowData)}
      tableHeader={`Connections for ${rowData.name}`}
      tableIcons={tableIcons}
      parentRecord={rowData}
      fieldType={FieldTypeEnum.CONNECTIONS}
      handleChange={handleChange}
    />
  );
}
