import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import _ from "lodash";

class FaqDataService {
  db;

  constructor() {
    this.db = firebase.firestore();
  }

  getAll = () => {
    return this.db
      .collection("faq")
      .get()
      .then((docList) => {
        let items = [];
        docList.docs.forEach((doc) => {
          // console.log(doc.data());
          items.push(doc.data());
        });
        // ordering the data by title column
        if (items && items.length) {
          items = _.orderBy(items, ["title"], ["asc"]);
        }
        return items;
      })
      .catch((error) => error);
  };

  create = async (links) => {
    const batch = this.db.batch();
    links.map((item) => {
      const collectionRef = this.db.collection("faq").doc(item.id);
      batch.set(collectionRef, item);
    });

    await batch
      .commit()
      .then()
      .catch((error) => error);
  };

  delete = (linkIds) => {
    linkIds.map((id) => {
      return this.db
        .collection("faq")
        .doc(id)
        .delete()
        .then()
        .catch((error) => error);
    });
  };
}

export default new FaqDataService();
