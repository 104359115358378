/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setUserData } from "../redux/actions/UserActions";
import { getNavigationByUser } from "../redux/actions/NavigationAction";
import LocalStorageService from "../services/LocalStorageService";
import FirebaseAuthService from "../services/firebase/FirebaseAuthService";
import history from "history.js";

const checkFirebaseAuth = (setUserData) => {
  FirebaseAuthService.checkAuthStatus((user) => {
    const ls = LocalStorageService.getItem("auth_user");
    if (user && ls && ls.uid === user.uid) {
      // console.log(user.uid);
      // console.log(user.email);
      // console.log(user.emailVerified);
      console.log("already logged in");
      setUserData(ls);
      history.push({
        pathname: "/homepage",
      });
    } else {
      console.log("not logged in");
      history.push({
        pathname: "/signin",
      });
    }
    return user;
  });
};

const Auth = ({ children, setUserData, getNavigationByUser, login }) => {
  // const ls = LocalStorageService.getItem("auth_user");
  // setUserData(LocalStorageService.getItem("auth_user"));

  useEffect(() => {

    checkFirebaseAuth(setUserData);
    getNavigationByUser();
  }, [setUserData, getNavigationByUser]);

  return <Fragment>{children}</Fragment>;
};

const mapStateToProps = (state) => ({
  setUserData: PropTypes.func.isRequired,
  getNavigationByUser: PropTypes.func.isRequired,
  login: state.login,
});

export default connect(mapStateToProps, { setUserData, getNavigationByUser })(
  Auth
);
