import React from "react";
import MaterialTable from "material-table";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  useStyles,
  getEditableComponent,
  getOptions
} from "../../helpers/DataTable";
import {tableIcons} from "../../components/ReusableComponents/DatatableIconsComponent";
import {RolesTableComponent} from "../../components";
import {getFaqColumns} from "helpers/FaqDataTable";

export default function FaqDatatableComponent(props) {
  const {data, isLoading, handleFaqChange, handleFaqRefresh} = props;
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <Container className={classes.container}>
        {/* JSON viewer conatainer*/}
        <Grid container className={classes.viewerSettings}>
          <Grid item xs={12} md={8} lg={12}>
            <div style={{maxWidth: "100%"}}>
              <MaterialTable
                isLoading={isLoading}
                icons={tableIcons}
                columns={getFaqColumns()}
                data={data}
                title={
                  <div className={classes.iconContainer}>
                    <SettingsIcon />
                    <h2 className={classes.headerText}>
                    Help{" "}
                    </h2>
                  </div>
                }
                options={getOptions}
                editable={getEditableComponent(data, handleFaqChange)}
                actions={[
                  {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    onClick: () => handleFaqRefresh(),
                  },
                ]}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
