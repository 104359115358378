import React, {useEffect, useState} from "react";
import "./accordion.style.scss";
import FaqDataService from "services/firebase/FaqDataService";
import dyngLegend from "../../../resources/images/dyng_legend.png";
import dyngOverview from "../../../resources/images/dyng_overview.png";

let path = null;

let margin = {top: 25, right: 90, bottom: 30, left: 90};
//let width4OrgChart = 1660 - margin.left - margin.right;
// let height4OrgChart = 1500 - margin.top - margin.bottom;
let width4OrgChart = window.innerWidth * 0.9;
let height4OrgChart = window.innerHeight;

export default function FaqComponent(props) {
  const [state, setState] = useState({
    faq: [],
    isLoading: false,
  });

  const {faq, isLoading} = state;

  useEffect(() => {
    let mounted = true;
    let faq = [];

    // Getting faq from firebase
    FaqDataService.getAll().then((items) => {
      if (mounted) {
        faq = items;
        setState({...state, faq: faq});
      }
    });
    return () => (mounted = false);
  }, []);

  const getFaqItems = () => {
    return (
      faq &&
      faq.map((item) => {
        return (
          <div className="card">
            <div className="card-header" id={`heading${item.id}`}>
              <h2 className="clearfix mb-0">
                <a
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target={`#collapse${item.id}`}
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  <i className="fa fa-chevron-circle-down"></i> {item.title}
                </a>
              </h2>
            </div>
            <div
              id={`collapse${item.id}`}
              className="collapse show"
              aria-labelledby={`heading${item.id}`}
              data-parent="#accordionExample">
              <div className="card-body">
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}></p>
              </div>
            </div>
          </div>
        );
      })
    );
  };

  return (
    <div className="wrapper">
      <div className="js-viewFAQ">
        <div className="container-lg">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="page-title">HELP</h1>
              <div className="accordion" id="accordionExample">
                {getFaqItems()}
                {/* <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="clearfix mb-0">
                      <a
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne">
                        <i className="fa fa-chevron-circle-down"></i> Centers of
                        Competence (CoCs)
                      </a>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <p>
                        Serve to divide tasks of the company into 5 main
                        ordering clusters. CoCs accommodate Competence Teams.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <a
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree">
                        <i className="fa fa-chevron-circle-down"></i> Competence
                        Teams
                      </a>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <p>
                        Are mono-competent units in CoCs. Competence Teams
                        either accommodate several expert teams or are home to
                        experts – depending on the number of organizational
                        layers.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <a
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse4"
                        aria-expanded="false"
                        aria-controls="collapse4">
                        <i className="fa fa-chevron-circle-down"></i> Countries
                      </a>
                    </h2>
                  </div>
                  <div
                    id="collapse4"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <ul>
                        <li>
                          The countries were initially out of scope when
                          designing the dynamogram
                        </li>
                        <li>They may be integrated in the year 2021ff.</li>
                        <li>
                          In this regard, International Distributor Business is
                          treated in the same way as a country.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <a
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse5"
                        aria-expanded="false"
                        aria-controls="collapse5">
                        <i className="fa fa-chevron-circle-down"></i>{" "}
                        Cross-Competence Teams
                      </a>
                    </h2>
                  </div>
                  <div
                    id="collapse5"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <p>
                        Gather experts from several different competences. They
                        can be temporarily or permanent (e.g. PLTs). They can be
                        clearly distinguished from projects and programs.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <a
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse6"
                        aria-expanded="false"
                        aria-controls="collapse6">
                        <i className="fa fa-chevron-circle-down"></i> Dual
                        Organization
                      </a>
                    </h2>
                  </div>
                  <div
                    id="collapse6"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <p>
                        A dual organization combines two operating systems:
                        functional hierarchies where needed and a dynamic
                        cross-functional network where possible. It is thus a
                        combination of a traditional, hierarchical organization
                        and a dynamic network organization. This organizational
                        form increases agility by keeping efficiency for
                        predicable business. The key to success is an alignment
                        between both systems. Such a dual organization is
                        illustrated in our Dynamogram.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <a
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse7"
                        aria-expanded="false"
                        aria-controls="collapse7">
                        <i className="fa fa-chevron-circle-down"></i> Dynamogram
                      </a>
                    </h2>
                  </div>
                  <div
                    id="collapse7"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <p>
                        A dynamogram is <strong>not</strong> an organizational
                        structure. Rather, it is a different way to{" "}
                        <strong>illustrate and design</strong> an organization
                        which has collaboration among employees in its focus.
                        This goes hand in hand with a{" "}
                        <strong>different mindset</strong> of how to{" "}
                        <strong>collaborate</strong>:
                        <ul>
                          <li>
                            <strong>Executive Board in the center</strong>
                          </li>
                          <li>
                            Constant <strong>interaction</strong> instead of
                            silos
                          </li>
                          <li>
                            Adapting <strong>dynamically</strong> to market
                            conditions and changing needs of Vorwerk community
                          </li>
                          <li>
                            <strong>Different ways of working</strong> under one
                            roof: some parts of the organization might still
                            work in a hierarchical setting, others more
                            self-organized
                          </li>
                          <li>
                            <strong>Vorwerk community</strong> integrated, e.g.
                            in cross-competence teams{" "}
                          </li>
                          <li>
                            <strong>Clear roles and responsibilities</strong>{" "}
                            and empowering leadership
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <a
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse8"
                        aria-expanded="false"
                        aria-controls="collapse8">
                        <i className="fa fa-chevron-circle-down"></i> Employment
                        contract
                      </a>
                    </h2>
                  </div>
                  <div
                    id="collapse8"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <p>Add from legal assessment</p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <a
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse9"
                        aria-expanded="false"
                        aria-controls="collapse9">
                        <i className="fa fa-chevron-circle-down"></i> Expert
                        Teams / Expert Groups
                      </a>
                    </h2>
                  </div>
                  <div
                    id="collapse9"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <p>
                        Constitute additional hierarchical layers below the
                        Competence Teams. These additional layers can be added
                        to rather hierarchical CoCs.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <a
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse10"
                        aria-expanded="false"
                        aria-controls="collapse10">
                        <i className="fa fa-chevron-circle-down"></i> Leadership
                        Principles
                      </a>
                    </h2>
                  </div>
                  <div
                    id="collapse10"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <p>
                        <strong>1. We give Purpose</strong>
                        <br />
                        We make purpose visible and provide its concrete meaning. We inspire our communities by transparent
                        communication as the base for direction and decision-making.
                        <br />
                        <strong>2. We foster Autonomy.</strong> <br />
                        We empower our communities to enjoy their work and to
                        succeed. We give our teams both the freedom they need to
                        self-organize and the accountability for the results.
                        <br />
                        <strong>3. We give and earn Trust.​</strong>
                        <br />
                        Trust is a human core need and at the basis of our business. We trust into the ability and
                        responsibility of teams and stick to our word. We invest
                        in lasting partnerships with our communities (advisors,
                        customers, employees).
                        <br />
                        <strong>4. We Innovate courageously.​</strong>
                        <br />
                        We enable continuous improvement and lifelong learning by giving and receiving ongoing feedback. We drive community centricity and innovation in high performing teams with great passion and the courage to push the limits. 
                        <br />
                        <strong>5. We Walk the talk.​</strong>
                        <br />
                        As a leader, I make sure that my words are congruent
                        with my actions. I do what I say I am going to do, and
                        I transparently provide direction about my view on where
                        we stand and where we need to go. My team can rely on
                        me.
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
              */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
