import React from "react";
import MaterialTable from "material-table";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  useStyles,
  getEditableComponent,
  getOptions,
} from "../../helpers/DataTable";
import {getLinkColumns} from "../../helpers/LinkDataTable";
import {tableIcons} from "../../components/ReusableComponents/DatatableIconsComponent";

export default function LinksDatatableComponent(props) {
  const {nodes, data, isLoading, handleLinksChange, handleLinkRefresh} = props;
  const classes = useStyles();
  const columns = getLinkColumns(nodes);
  return (
    <main className={classes.content}>
      <Container className={classes.container}>
        {/* JSON viewer conatainer*/}
        <Grid container className={classes.viewerSettings}>
          <Grid item xs={12} md={8} lg={12}>
            <div style={{maxWidth: "100%"}}>
              <MaterialTable
                isLoading={isLoading}
                icons={tableIcons}
                columns={columns}
                data={data}
                title={
                  <div className={classes.iconContainer}>
                    <SettingsIcon />
                    <h2 className={classes.headerText}>
                      Lines{" "}
                    </h2>
                  </div>
                }
                options={getOptions}
                editable={getEditableComponent(data, handleLinksChange)}
                actions={[
                  {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    onClick: () => handleLinkRefresh(),
                  },
                ]}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
